import React, { useEffect, useMemo, useState } from 'react';

import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { gridSpacing } from 'store/constant';

import MainCard from 'ui-component/cards/MainCard';

import FormTopContainer from 'ui-component/dashboard/FormTopContainer';
import SubCard from 'ui-component/cards/SubCard';
import DropDown from 'common/material/Dropdown';
import InputField from 'common/material/InputField';

import { ReactComponent as UserOutlineCameraIcon } from 'assets/images/users/user-outline-camera.svg';
import ToggleSwitch from 'common/material/ToggleSwitch';
import { useAdvisorDetailStyles } from './AdvisorDetailStyle';
import {
  requestBlockOrAllowAdvisorPortalAccess,
  requestFindAdvisorDetail,
} from 'redux/Actions/advisorProfileAction';
import LoadingSpinner from 'ui-component/LoadingSpinner';
import { getOptionsData } from 'utils/compositeFormat';
import PaymentHistoryDetailsModal from './modals/PaymentHistoryDetailsModal';
import { IconEyeCheck, IconEyeTable, IconEyeglass2, IconScanEye } from '@tabler/icons';
import { PaymentOutlined, TableView, TableViewOutlined } from '@mui/icons-material';

const AdvisorDetails = () => {
  const { classes: advisorDetailsClasses } = useAdvisorDetailStyles();
  /**
   * Uses react-router-dom location
   */
  const location = useLocation();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  const advisorProfileReducer = useSelector((state) => state?.advisorProfileReducer);
  const isGetFindAdvisorDetailByIdRequestLoading =
    advisorProfileReducer?.isGetFindAdvisorDetailByIdRequestLoading;
  const selectedAdvisorDetailSuccessData = advisorProfileReducer?.selectedAdvisorDetailSuccessData;

  //composite data loading
  const { compositeRequestLoading } = useSelector((state) => state.compositeDataReducer);
  /**
   * Memoized option data
   */
  const optionsData = useMemo(() => {
    let [genderOptions, relationshipOptions] = [
      getOptionsData('title'),
      getOptionsData('relationshipToClient'),
    ];
    return {
      genderOptions,
      relationshipOptions,
    };
  }, [compositeRequestLoading]);

  const [passwordFieldData, setPasswordFieldData] = useState({
    newPassword: '',
    confirmPassword: '',
    errors: {
      confirmPassword: '',
      newPassword: '',
    },
  });

  const [paymentHistoryModalData, setPaymentHistoryModalData] = useState({
    isOpened: false,
    data: null,
  });
  /**
   * Handle open or close payment confirmation data
   * @param {*} isOpened
   * @param {*} confirmationData
   */
  const handleOpenOrClosePaymentHistoryDetailsModal = (isOpened, confirmationData = null) => {
    setPaymentHistoryModalData((prev) => ({
      ...prev,
      isOpened,
      data: {
        ...(prev?.data || {}),
        ...(confirmationData || {}),
      },
    }));
  };
  /**
   * Change Password fields
   * @param {*} event
   * @param {*} fieldType
   */
  const handlePasswordFieldChange = (event, fieldType) => {
    setPasswordFieldData((prev) => ({
      ...prev,
      [fieldType]: event?.target?.value || '',
      errors: {
        ...prev.errors,
        confirmPassword:
          (((fieldType === 'confirmPassword' && prev.newPassword !== event?.target?.value) ||
            (fieldType === 'newPassword' && event?.target?.value !== prev?.confirmPassword)) &&
            'Invalid Confirmation Password !') ||
          '',
        newPassword:
          (((fieldType === 'confirmPassword' && !prev.newPassword && event?.target?.value) ||
            (fieldType === 'newPassword' && prev.confirmPassword && !event?.target?.value)) &&
            'Password is Required !') ||
          '',
      },
    }));
  };

  /**
   * On camera icon click
   * @param {*} event
   */
  const handleOnAvatarClick = (event) => {};

  const handleToggleBlockAdvisor = (event) => {
    const isChecked = event?.target?.checked;
    const advisorData = location?.state?.advisorData;

    const requestDetails = {
      advisorId: advisorData?._id,
      requestBody: {
        blockUser: isChecked,
      },
    };
    const handleRequestSuccessCallback = (response) => {
      handleGetAdvisorDetails();
    };
    dispatch(requestBlockOrAllowAdvisorPortalAccess(requestDetails, handleRequestSuccessCallback));
  };
  /**
   * Submit change password
   */
  const handleSubmitChangePassword = () => {};
  /**
   * Format statistic
   * @param {*} value
   * @returns
   */
  const formatStatistic = (value) => {
    return (value.toString && value.toString().padStart(2, '0')) || '00';
  };
  /**
   * Open Payment History modal
   * @param {*} event
   */
  const handleOpenPaymentHistory = (event) => {
    event?.preventDefault && event.preventDefault();
    event?.stopPropagation && event.stopPropagation();

    handleOpenOrClosePaymentHistoryDetailsModal(true, {
      advisorData: selectedAdvisorDetailSuccessData || location?.state?.advisorData,
    });
  };
  /**
   * Handle get advisor details
   */
  const handleGetAdvisorDetails = () => {
    const requestDetail = {
      accountOwnerId: location?.state?.advisorData?._id,
    };
    dispatch(requestFindAdvisorDetail(requestDetail));
  };
  /**
   * Effect on navigation location
   */
  useEffect(() => {
    if (location.state?.advisorData?._id) {
      handleGetAdvisorDetails();
    }
  }, [location.state?.advisorData?._id]);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Advisor Profile'} />
        </MainCard>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          {isGetFindAdvisorDetailByIdRequestLoading && (
            <LoadingSpinner isLoadingSpinner={isGetFindAdvisorDetailByIdRequestLoading} />
          )}
          <PaymentHistoryDetailsModal
            isOpened={paymentHistoryModalData?.isOpened}
            confirmationData={paymentHistoryModalData?.data}
            handleDialogOpened={handleOpenOrClosePaymentHistoryDetailsModal}
          />
          <Grid item xs={12} md={4}>
            <SubCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1, padding: '0 !important' }}
            >
              <Grid container spacing={1} alignItems="center" alignContent="center">
                <Grid item xs={3} m={2}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.green?.[450],
                      fontSize: '3.375rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    {formatStatistic(selectedAdvisorDetailSuccessData?.noOfClients || 0)}
                  </Typography>
                </Grid>
                <Grid item xs m={2}>
                  <Typography
                    textAlign={'end'}
                    sx={(theme) => ({
                      color: theme.palette.blue?.[750],
                      fontSize: '1.125rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    Total Clients
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={12} m={2.5}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.blue?.[500],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(selectedAdvisorDetailSuccessData?.noOfClients || 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Active
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={1} textAlign="end">
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.red?.[750],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Inactive
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SubCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1, padding: '0 !important' }}
            >
              <Grid container spacing={1} alignItems="center" alignContent="center">
                <Grid item xs={3} m={2}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.green?.[450],
                      fontSize: '3.375rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    {formatStatistic(selectedAdvisorDetailSuccessData?.noOfLeads || 0)}
                  </Typography>
                </Grid>
                <Grid item xs m={2}>
                  <Typography
                    textAlign={'end'}
                    sx={(theme) => ({
                      color: theme.palette.blue?.[750],
                      fontSize: '1.125rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    Total Leads
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={12} m={2.5}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.blue?.[500],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(selectedAdvisorDetailSuccessData?.noOfClients || 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Active
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={1} textAlign="end">
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.red?.[750],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Inactive
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <SubCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1, padding: '0 !important' }}
            >
              <Grid container spacing={1} alignItems="center" alignContent="center">
                <Grid item xs={3} m={2}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.green?.[450],
                      fontSize: '3.375rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    {formatStatistic(selectedAdvisorDetailSuccessData?.noOfTeamMembers || 0)}
                  </Typography>
                </Grid>
                <Grid item xs m={2}>
                  <Typography
                    textAlign="end"
                    sx={(theme) => ({
                      color: theme.palette.blue?.[750],
                      fontSize: '1.125rem',
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    Total Team Member
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={12} m={2.6}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.blue?.[500],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(
                              selectedAdvisorDetailSuccessData?.noOfTeamMembers || 0
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Active
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={1} textAlign="end">
                        <Grid item xs={12}>
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.red?.[750],
                              fontSize: '2rem',
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            {formatStatistic(0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textTransform={'uppercase'}
                            sx={(theme) => ({
                              color: theme.palette.grey?.[450],
                              fontWeight: theme.typography.fontWeightMedium,
                            })}
                          >
                            Inactive
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <MainCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1 }}
            >
              <Grid container spacing={1} alignItems="center" alignContent="flex-start">
                <Grid item xs={12}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.blue?.[700],
                      fontSize: '1rem',
                      fontWeight: theme.typography.fontWeightMedium,
                    })}
                  >
                    Personal Details
                  </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <Avatar
                    sx={{
                      width: '6rem',
                      height: '6rem',
                      color: 'transparent',
                      background: 'transparent',
                      cursor: 'pointer',
                    }}
                    imgProps={{
                      style: {
                        objectFit: 'contain',
                      },
                    }}
                    src={selectedAdvisorDetailSuccessData?.profilePicture || undefined}
                    // onClick={handleOnAvatarClick}
                  >
                    {!selectedAdvisorDetailSuccessData?.profilePicture && (
                      <SvgIcon
                        sx={{ width: '6rem', height: '6rem' }}
                        viewBox="0 0 72 80"
                        component={UserOutlineCameraIcon}
                      />
                    )}
                  </Avatar>
                </Grid>
                {/* <Grid item xs={12}>
                  <InputLabel className={advisorDetailsClasses?.advisorPersonalDetailInputLabel}>
                    Title
                  </InputLabel>
                  <DropDown
                    value={1}
                    optionData={[
                      { id: 1, label: 'Mr. ' },
                      { id: 2, label: 'Ms. ' },
                      { id: 3, label: 'Mss. ' },
                    ]}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <InputLabel className={advisorDetailsClasses?.advisorPersonalDetailInputLabel}>
                    First Name
                  </InputLabel>
                  <InputField
                    value={selectedAdvisorDetailSuccessData?.firstName}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={advisorDetailsClasses?.advisorPersonalDetailInputLabel}>
                    Sur Name
                  </InputLabel>
                  <InputField
                    value={selectedAdvisorDetailSuccessData?.surName}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={advisorDetailsClasses?.advisorPersonalDetailInputLabel}>
                    Username
                  </InputLabel>
                  <InputField
                    value={selectedAdvisorDetailSuccessData?.emailAddress}
                    fullWidth
                    disabled
                    sx={(theme) => ({
                      '& input': {
                        backgroundColor: theme.palette.blue?.[400],
                        color: theme.palette.background.paper,

                        '& div': {
                          color: theme.palette.background.paper,
                        },
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </MainCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <MainCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1 }}
            >
              <Grid container spacing={1} alignItems="center" alignContent="flex-start">
                <Grid item xs={12}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.blue?.[700],
                      fontSize: '1rem',
                      fontWeight: theme.typography.fontWeightMedium,
                    })}
                  >
                    Payment Status
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography>Advisor Payment</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Chip
                        label={
                          location?.state?.advisorData?.paymentStatus ||
                          selectedAdvisorDetailSuccessData?.paymentStatus
                            ? 'Paid'
                            : 'Not Paid'
                        }
                        sx={(theme) => ({
                          color: theme.palette.background.paper,
                          fontWeight: theme.typography.fontWeightMedium,
                          backgroundColor:
                            location?.state?.advisorData?.paymentStatus ||
                            selectedAdvisorDetailSuccessData?.paymentStatus
                              ? theme.palette.green?.[400]
                              : theme.palette.red?.[800],
                          paddingInline: '1rem',
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Typography>Do you want to Block this ?</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <ToggleSwitch
                        checked={
                          typeof selectedAdvisorDetailSuccessData?.userActiveStatus !==
                            'undefined' && !selectedAdvisorDetailSuccessData?.userActiveStatus
                        }
                        onClick={handleToggleBlockAdvisor}
                        className={advisorDetailsClasses?.advisorPaymentBlockSwitch}
                        disabled={
                          selectedAdvisorDetailSuccessData?.userActiveStatus === 'undefined'
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={gridSpacing}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.blue?.[700],
                        fontSize: '1rem',
                        fontWeight: theme.typography.fontWeightMedium,
                      })}
                    >
                      Payment History
                    </Typography>
                    <IconButton
                      color="green"
                      id="viewPaymentHistoryBtn"
                      onClick={handleOpenPaymentHistory}
                    >
                      <PaymentOutlined />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <MainCard
              sx={{ display: 'flex', height: '100%' }}
              contentSX={{ display: 'flex', flexGrow: 1 }}
            >
              <Grid container spacing={3} alignContent="flex-start">
                <Grid item xs={12} mb={4}>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.blue?.[700],
                      fontSize: '1rem',
                      fontWeight: theme.typography.fontWeightMedium,
                    })}
                  >
                    Change Password
                  </Typography>
                </Grid>
                {/* <Grid item xs={2}>
                  <Checkbox color="red" />
                </Grid>
                <Grid item xs={10}>
                  <InputField type="password" label="Existing Password" placeholder="***" />
                </Grid> */}
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={12}>
                  <InputField
                    value={passwordFieldData?.newPassword || ''}
                    onChange={(event) => handlePasswordFieldChange(event, 'newPassword')}
                    type="password"
                    label="New Password"
                    error={!!passwordFieldData?.errors?.newPassword}
                    helperText={passwordFieldData?.errors?.newPassword}
                    fullWidth
                  />
                </Grid>
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={12}>
                  <InputField
                    value={passwordFieldData?.confirmPassword || ''}
                    onChange={(event) => handlePasswordFieldChange(event, 'confirmPassword')}
                    type="password"
                    label="Confirm Password"
                    error={!!passwordFieldData?.errors?.confirmPassword}
                    helperText={passwordFieldData?.errors?.confirmPassword}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSubmitChangePassword}
                    disabled
                    sx={(theme) => ({
                      mt: 4,
                      color: theme.palette.background.paper,
                      fontWeight: theme.typography.fontWeightBold,
                      backgroundColor: theme.palette.green?.[400],
                      '&:hover': {
                        color: theme.palette.background.paper,
                        fontWeight: theme.typography.fontWeightBold,
                        backgroundColor: theme.palette.green?.[400],
                      },
                    })}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorDetails;
