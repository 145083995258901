import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //promo codes data request
    isPromoCodesGetAllRequestLoading: false,
    promoCodesData: [],
    promoCodesFailed: {},

    //save promo codes
    isPostCreatePromoCodeRequestLoading: false,
    promoCodeCreateRequestSuccessData: {},
    promoCodeCreateRequestFailedData: {},
};

export const promoCodesDataReducer = createReducer(initialState, {
    //get promo codes
    getAllPromoCodesRequestRequest: (state) => {
        state.isPromoCodesGetAllRequestLoading = true;
        state.promoCodesData = [];
        state.promoCodesFailed = {};
    },
    getAllPromoCodesRequestSuccess: (state, action) => {
        state.isPromoCodesGetAllRequestLoading = false;
        state.promoCodesData = action.payload;
        state.promoCodesFailed = {};
    },
    getAllPromoCodesRequestFailed: (state, action) => {
        state.isPromoCodesGetAllRequestLoading = false;
        state.promoCodesData = [];
        state.promoCodesFailed = action.payload;
    },

    // post save promo code
    postCreatePromoCodeRequest: (state) => {
        state.isPostCreatePromoCodeRequestLoading = true;
        state.promoCodeCreateRequestSuccessData = true;
        state.promoCodeCreateRequestFailedData = true;
    },
    postCreatePromoCodeRequestSuccess: (state, action) => {
        state.isPostCreatePromoCodeRequestLoading = false
        state.promoCodeCreateRequestSuccessData = action.payload
        state.promoCodeCreateRequestFailedData = {}
    },
    postCreatePromoCodeRequestFailed: (state, action) => {
        state.isPostCreatePromoCodeRequestLoading = false
        state.promoCodeCreateRequestSuccessData = {}
        state.promoCodeCreateRequestFailedData = action.payload
    },

});
