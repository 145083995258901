import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  // get all email templates
  isGetAllEmailTemplatesLoading: false,
  emailTemplatesData: null,
  emailTemplatesFailedData: {},

  // search email template
  isSearchEmailTemplateLoading: false,
  emailTemplateSearchSuccessData: null,
  emailTemplateSearchFailedData: {},

  // save email template
  isSaveEmailTemplateLoading: false,
  emailTemplateSaveSuccessData: {},
  emailTemplateSaveFailedData: {},

  //delete email template
  isDeleteEmailTemplateLoading: false,
  emailTemplateDeleteSuccessData: {},
  emailTemplateDeleteFailedData: {},
};

/**
 * Client Invitation reducer
 */
export const emailTemplateReducer = createReducer(initialState, {
  // get all email Templates
  getAllEmailTemplatesRequest: (state) => {
    state.isGetAllEmailTemplatesLoading = true;
    state.emailTemplatesData = null;
    state.emailTemplatesFailedData = {};
  },
  getAllEmailTemplatesRequestSuccess: (state, action) => {
    state.isGetAllEmailTemplatesLoading = false;
    state.emailTemplatesData = action.payload;
    state.emailTemplatesFailedData = {};
  },
  getAllEmailTemplatesRequestFailed: (state, action) => {
    state.isGetAllEmailTemplatesLoading = false;
    state.emailTemplatesData = null;
    state.emailTemplatesFailedData = action.payload;
  },

  // save email template
  postSearchEmailTemplateRequest: (state) => {
    state.isSearchEmailTemplateLoading = true;
    state.emailTemplateSearchSuccessData = null;
    state.emailTemplateSearchFailedData = {};
  },
  postSearchEmailTemplateRequestSuccess: (state, action) => {
    state.isSearchEmailTemplateLoading = false;
    state.emailTemplateSearchSuccessData = action.payload;
    state.emailTemplateSearchFailedData = {};
  },
  postSearchEmailTemplateRequestFailed: (state, action) => {
    state.isSearchEmailTemplateLoading = false;
    state.emailTemplateSearchSuccessData = null;
    state.emailTemplateSearchFailedData = action.payload;
  },

  // save email template
  postSaveEmailTemplateRequest: (state) => {
    state.isSaveEmailTemplateLoading = true;
    state.emailTemplateSaveSuccessData = {};
    state.emailTemplateSaveFailedData = {};
  },
  postSaveEmailTemplateRequestSuccess: (state, action) => {
    state.isSaveEmailTemplateLoading = false;
    state.emailTemplateSaveSuccessData = action.payload;
    state.emailTemplateSaveFailedData = {};
  },
  postSaveEmailTemplateRequestFailed: (state, action) => {
    state.isSaveEmailTemplateLoading = false;
    state.emailTemplateSaveSuccessData = {};
    state.emailTemplateSaveFailedData = action.payload;
  },

  // delete email template
  deleteEmailTemplateRequest: (state) => {
    state.isDeleteEmailTemplateLoading = true;
    state.emailTemplateDeleteSuccessData = {};
    state.emailTemplateDeleteFailedData = {};
  },
  deleteEmailTemplateRequestSuccess: (state, action) => {
    state.isDeleteEmailTemplateLoading = false;
    state.emailTemplateDeleteSuccessData = action.payload;
    state.emailTemplateDeleteFailedData = {};
  },
  deleteEmailTemplateRequestFailed: (state, action) => {
    state.isDeleteEmailTemplateLoading = false;
    state.emailTemplateDeleteSuccessData = {};
    state.emailTemplateDeleteFailedData = action.payload;
  },

  // delete email template
  putUpdateEmailTemplateRequest: (state) => {
    state.isUpdateEmailTemplateRequestLoading = true;
    state.emailTemplateDeleteSuccessData = {};
    state.emailTemplateDeleteFailedData = {};
  },
  putUpdateEmailTemplateRequestSuccess: (state, action) => {
    state.isUpdateEmailTemplateRequestLoading = false;
    state.emailTemplateDeleteSuccessData = action.payload;
    state.emailTemplateDeleteFailedData = {};
  },
  putUpdateEmailTemplateRequestFailed: (state, action) => {
    state.isUpdateEmailTemplateRequestLoading = false;
    state.emailTemplateDeleteSuccessData = {};
    state.emailTemplateDeleteFailedData = action.payload;
  },
});
