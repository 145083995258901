import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { requestCompositeData } from './compositesActions';
import { handleNotification } from './handleNotificationAction';

/**
 * Sign In Request Action
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestSignIn =
  (requestBody, handleLoginSuccessCallback, handleLoginFailCallback, isReAuthentication = false) =>
    async (dispatch, getState) => {
      dispatch({ type: 'postLoginRequest' });

      const requestData = {
        url: API_URL.auth.authentication.POST_SIGN_IN,
        method: 'POST',
        data: requestBody,
        timeout: 10000,
      };
      const successCallback = (response) => {
        if (response.data?.token) {
          dispatch({ type: 'postLoginSuccess', payload: response?.data });

          Store.dispatch(
            handleNotification(
              `Signed In is Successful !`,
              'success'
            )
          );
          handleLoginSuccessCallback(response?.data);

          //get composites
          //* ENUMS
          dispatch(requestCompositeData());
        } else {
          dispatch({
            type: 'postLoginFailed',
            payload: { ...(response || {}), message: 'Authentication Failed !' },
          });
          handleLoginSuccessCallback({ navigateTo: '/' });

          //* Reset Composites
          dispatch({ type: 'resetCompositeData' });
        }
      };
      const errorCallback = (error) => {
        dispatch({ type: 'postLoginFailed', payload: error });
        Store.dispatch(handleNotification('Sign In Failed', 'error'));
        handleLoginFailCallback && handleLoginFailCallback();
        console.log(error);

        //* reset composites
        dispatch({ type: 'resetCompositeData' });
      };
      http_request(requestData, successCallback, errorCallback);
    };
/**
 * Handle Sign Out
 */
export const handleSignOut = (handleNavigationCallback) => async (dispatch, getState) => {
  handleNavigationCallback && handleNavigationCallback('/');
  dispatch({ type: 'handleUserSignOut' });

  //* reset redux store
  dispatch({ type: 'DESTROY_SESSION'})
};

/**
 * Open Re-Authentication
 * @param {*} reAuthData
 * @returns
 */
export const openReAuthenticateModal =
  (reAuthData = {}) =>
    async (dispatch, getState) => {
      dispatch({ type: 'openReAuthenticateModal', payload: reAuthData });
    };
/**
 * Close Re-Authentication
 * @returns
 */
export const closeReAuthenticateModal = () => async (dispatch, getState) => {
  dispatch({ type: 'closeReAuthenticateModal' });
};
