// assets
import {
  IconSettings,
  IconTicket,
  IconTemplate,
  IconCoin,
  IconServer2,
  IconAbacus,
  IconNotification,
  IconUsers,
  IconMessage2,
} from '@tabler/icons';

// constant
const icons = {
  IconSettings,
  IconTicket,
  IconTemplate,
  IconCoin,
  IconServer2,
  IconAbacus,
  IconNotification,
  IconUsers,
  IconMessage2,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: null,
  type: 'group',
  isDivider: false,
  children: [
    {
      id: 'advisorSettingIcon',
      title: 'Advisor Settings',
      type: 'item',
      url: '/main/settings',
      breadcrumbs: true,
      icon: icons.IconSettings,
    },
    {
      id: 'emailIcon',
      title: 'Email',
      type: 'item',
      url: '/main/email',
      breadcrumbs: true,
      icon: icons.IconMessage2,
    },
    {
      id: 'emailIcon',
      title: 'Email Templates',
      type: 'item',
      url: '/main/email-template',
      breadcrumbs: true,
      icon: icons.IconMessage2,
    },
    {
      id: 'promoCodesIcon',
      title: 'Promo Codes',
      type: 'item',
      url: '/main/promoCodes',
      breadcrumbs: true,
      icon: icons.IconTicket,
    },
    {
      id: 'wordTemplatesIcon',
      title: 'Word Templates',
      type: 'item',
      url: '/main/wordTemplates',
      breadcrumbs: true,
      icon: icons.IconTemplate,
    },
    {
      id: 'superannuationIcon',
      title: 'Superannuation',
      type: 'item',
      url: '/main/superannuation',
      breadcrumbs: true,
      icon: icons.IconCoin,
    },
    {
      id: 'subscriptionsIcon',
      title: 'Subscriptions',
      type: 'item',
      url: '/main/subscriptions',
      breadcrumbs: true,
      icon: icons.IconServer2,
    },
    {
      id: 'paymentHistoryIcon',
      title: 'Payment History',
      type: 'item',
      url: '/main/paymentHistory',
      breadcrumbs: true,
      icon: icons.IconAbacus,
    },
    {
      id: 'notificationsIcon',
      title: 'Notification',
      type: 'item',
      url: '/main/notifications',
      breadcrumbs: true,
      icon: icons.IconNotification,
    },
    // {
    //     id: 'omniumUsersIcon',
    //     title: 'Omnium Users',
    //     type: 'item',
    //     url: '/main/omniumUsers',
    //     breadcrumbs: true,
    //     icon: icons.IconUsers,
    // },
  ],
};

export default pages;
