export const handleNotification = (snackText, snackVariant) => async (dispatch, getState) => {
    dispatch({
        type: 'HANDLE_NOTIFICATION',
        payload: {
            snackText: snackText,
            snackVariant: snackVariant,
        },

    });
    setTimeout(() => {
        dispatch({
            type: 'HANDLE_NOTIFICATION',
            payload: {
                snackText: "",
                snackVariant: "",
            },
        });
    }, 2000)
};