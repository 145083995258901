import { isEmpty, isNil } from 'ramda';

/**
 * Get Dynamic Base from ENV
 * @returns {object} {Base URL, Current Environment}
 */
const handleGetBaseAndEnv = (customModule = null) => {
  let baseURL,
    currentEnv,
    currentPortal = null;
  //* DEFAULT ADVISOR_PORTAL BASE PATHS
  let DEV_BASE = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
  let QA_BASE = 'https://olv31xba27.execute-api.ap-southeast-2.amazonaws.com/';
  let UAT_BASE = 'https://c3h7ys0bea.execute-api.ap-southeast-2.amazonaws.com/';
  let LOCAL_BASE = 'http://localhost:4005/';
  let PROD_BASE = 'https://4sx6aiqch6.execute-api.ap-southeast-2.amazonaws.com/';

  let nodeProcessCurrentEnv = process.env?.['REACT_APP_ENV'];
  let nodeProcessCurrentPortal = process.env?.['REACT_APP_PORTAL'];

  currentEnv = nodeProcessCurrentEnv || 'dev';
  currentPortal = nodeProcessCurrentPortal || '';

  if (currentEnv === 'dev') {
    baseURL = DEV_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://6gg78zl6xh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://r9adqr1ii7.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://35m5yggfqc.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://4uxjknbwd9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://xprzs25820.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://jpzxtj2w59.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://aw95h1vvc5.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'qa') {
    baseURL = QA_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://oc8jqvm6rh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://3t64hxdis2.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://qrsu7plbnl.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://uw2mar3mi0.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://gf8cs1dfef.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://qftlp3qjob.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://fkzi5q0wbj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://afriywlf67.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'uat') {
    baseURL = UAT_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://plfen6k3ij.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://n1f9ojvem8.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://xzauwyu9gd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://9echz69v12.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://mpyn6nhfpf.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://j09qrb6yj9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://xkx44x1m0b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://75h9zuriz7.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'prod') {
    baseURL = PROD_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://k43y8ljsf1.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://8axoexlcpd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://e0ihwc9755.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://byhglthinj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://f981rsuilb.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://ddiplxum8f.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://63duoc1d1a.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://20di3qmlcg.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'local') {
    baseURL = LOCAL_BASE; //4005 httpPort
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'http://localhost:4001/';
      } else if (customModule === 'AUTH') {
        baseURL = 'http://localhost:4002/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'http://localhost:4003/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'http://localhost:4004/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'http://localhost:4006/';
      } else if (customModule === 'SMSF') {
        baseURL = 'http://localhost:4007/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'http://localhost:4008/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'http://localhost:4009/';
      }
    }
  }

  return { baseURL, currentEnv, currentPortal };
};

const { baseURL, currentEnv, currentPortal } = handleGetBaseAndEnv();

const basePath = baseURL + currentEnv;
//module base paths
const AuthModuleBasePath = `${handleGetBaseAndEnv('AUTH')?.baseURL}${
  handleGetBaseAndEnv('AUTH')?.currentEnv
}`;
const UtilsModuleBasePath = `${handleGetBaseAndEnv('UTILS')?.baseURL}${
  handleGetBaseAndEnv('UTILS')?.currentEnv
}`;
const PaymentsModuleBasePath = `${handleGetBaseAndEnv('PAYMENTS')?.baseURL}${
  handleGetBaseAndEnv('PAYMENTS')?.currentEnv
}`;
const AdminPortalModuleBasePath = `${handleGetBaseAndEnv('ADMIN_PORTAL')?.baseURL}${
  handleGetBaseAndEnv('ADMIN_PORTAL')?.currentEnv
}`;
const ClientPortalModuleBasePath = `${handleGetBaseAndEnv('CLIENT_PORTAL')?.baseURL}${
  handleGetBaseAndEnv('CLIENT_PORTAL')?.currentEnv
}`;

const API_URL = {
  baseURL,
  basePath,
  currentEnv,
  currentPortal,
  auth: {
    moduleVariable: 'AUTH',
    moduleBasePath: AuthModuleBasePath,
    authentication: {
      POST_SIGN_IN: AuthModuleBasePath + '/admin-login',
    },
  },
  composites: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    GET_ALL_ENUMS: UtilsModuleBasePath + '/enums',
  },
  dashboard: {
    moduleVariable: 'ADMIN_PORTAL',
    moduleBasePath: AdminPortalModuleBasePath,
    GET_ADMIN_DASHBOARD_STATISTICS: AdminPortalModuleBasePath + '/admin-dashboard/statistics',
  },
  promoCodes: {
    moduleVariable: 'PAYMENTS',
    moduleBasePath: PaymentsModuleBasePath,
    GET_ALL_PROMO_CODES: PaymentsModuleBasePath + '/promocodes',
    POST_SAVE_PROMO_CODE: PaymentsModuleBasePath + '/promocodes',
  },
  advisorProfile: {
    moduleVariable: 'ADMIN_PORTAL',
    moduleBasePath: AdminPortalModuleBasePath,
    GET_ALL_ADVISORS: AdminPortalModuleBasePath + '/admin-dashboard/account-owners',
    GET_FIND_ADVISOR_DETAIL_BY_ID:
      AdminPortalModuleBasePath + '/admin-dashboard/account-owners/{accountOwnerId}',
    PATCH_BLOCK_OR_ALLOW_ADVISOR_PORTAL_ACCESS:
      AdminPortalModuleBasePath + '/admin-dashboard/account-owners/{accountOwnerId}',
  },
  payments: {
    moduleVariable: 'PAYMENTS',
    moduleBasePath: PaymentsModuleBasePath,
    PUT_APPROVE_OR_REJECT_INCOMING_PAYMENT:
      PaymentsModuleBasePath + '/payments/{payerId}/{paymentId}',
    GET_ALL_PAYMENTS_OR_FILTERED_PAYMENTS:
      PaymentsModuleBasePath + '/payments?status={status}&payerId={payerId}&payeeId={payeeId}',
  },
  taxSettings: {
    moduleVariable: 'CLIENT_PORTAL',
    moduleBasePath: ClientPortalModuleBasePath,
    POST_CREATE_TAX_SETTING: ClientPortalModuleBasePath + '/taxSettings',
    PUT_UPDATE_TAX_SETTING: ClientPortalModuleBasePath + '/taxSettings/{taxSettingId}',
    GET_ALL_TAX_SETTINGS: ClientPortalModuleBasePath + '/taxSettings',
    POST_SEARCH_TAX_SETTINGS: ClientPortalModuleBasePath + '/taxSettings/search',
    POST_FIND_TAX_SETTING: ClientPortalModuleBasePath + '/taxSettings/findOne',
  },
  email: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    POST_SEND_EMAIL: UtilsModuleBasePath + '/emails',
    GET_SENT_EMAILS: UtilsModuleBasePath + '/emails/message', //format is /emails/{emailType}
    POST_BULK_SENT_EMAIL: UtilsModuleBasePath + '/emails/bulkEmail',

    POST_CREATE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/adminEmailTemplates',
    GET_ALL_EMAIL_TEMPLATES: UtilsModuleBasePath + '/adminEmailTemplates',
    PUT_UPDATE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/adminEmailTemplates/{emailTemplateId}',
    DELETE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/adminEmailTemplates/{emailTemplateId}',
  },
};

export { API_URL };
