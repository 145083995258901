import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Tabs, Tab, Box } from '@mui/material';

import Lottie from 'lottie-react';

import MainCard from 'ui-component/cards/MainCard';
import FormTopContainer from 'ui-component/dashboard/FormTopContainer';
import Compose from './component/Compose';

import { useCommonStyles } from 'styles/styles';

import { gridSpacing } from 'store/constant';

import * as noteBookWritingAnimationData from 'assets/animation/notebook-writing.json';

const TabPanel = memo((props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const BulkEmail = () => {
  const { classes: commonClasses } = useCommonStyles();

  //state
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    height: 60,
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Bulk Email'} />
        </MainCard>
      </Grid>

      <Grid container item xs={12}>
        <Card className={commonClasses.mainContainer_Wrap}>
          <Grid container>
            <Grid container item xs={12}>
              <Card className={commonClasses.mainContainer_Wrap}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  <Tab
                    icon={
                      <Box style={{ height: 60 }}>
                        <Lottie animationData={noteBookWritingAnimationData} style={style} />
                      </Box>
                    }
                    label="Compose"
                    {...a11yProps(0)}
                  />
                </Tabs>
                <TabPanel value={value} index={0} style={{ width: '100%' }}>
                  <Compose />
                </TabPanel>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(BulkEmail);
