import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Request Payments
 * @param {*} requestDetails 
 * @returns 
 */
export const requestGetPayments = (requestDetails, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) => async (dispatch, getState) => {
    dispatch({ type: 'getAllOrFilteredPaymentDataRequest', payload: { paymentsGetRequestFilters: requestDetails } });

    const {
        status, //outgoing, incoming
        payeeId,
        payerId,
    } = requestDetails
    /**
     * Get request url
     * @param {*} status 
     * @param {*} payeeId 
     * @returns 
     */
    const getRequestUrl = (status = null, payeeId = null, payerId = null) => {
        let requestURL = API_URL.payments.GET_ALL_PAYMENTS_OR_FILTERED_PAYMENTS;
        let requestURLPath1 = requestURL.split('?')[0]; // main path
        let requestURLPath2 = requestURL.split('?')[1]; // query params
        let requestURLStatusQueryPath = requestURLPath2.split('&')?.[0];
        let requestURLPayerIDQueryPath = requestURLPath2.split('&')?.[1];
        let requestURLPayeeIDQueryPath = requestURLPath2.split('&')?.[2];
        //payee , status
        if (status && payeeId && !payerId) {
            requestURL = (requestURLPath1 + '?' + requestURLStatusQueryPath + '&' + requestURLPayeeIDQueryPath).replace('{payeeId}', payeeId).replace('{status}', status);
        } else if (!status && payeeId && !payerId) {
            requestURL = requestURLPath1 + '?' + requestURLPayeeIDQueryPath;
            requestURL = requestURL.replace('{payeeId}', payeeId);
        }

        //payer , status 
        else if (status && payerId && !payeeId) {
            requestURL = (requestURLPath1 + '?' + requestURLStatusQueryPath + '&' + requestURLPayerIDQueryPath).replace('{payerId}', payerId).replace('{status}', status);
        } else if (!status && payerId && !payeeId) {
            requestURL = requestURLPath1 + '?' + requestURLPayeeIDQueryPath;
            requestURL = requestURL.replace('{payerUd}', payerId);
        }

        //status only
        else if (status && !payerId && !payeeId) {
            requestURL = requestURLPath1 + '?' + requestURLStatusQueryPath;
            requestURL = requestURL.replace('{status}', status);
        }
        //none
        else if (!status && !payerId && !payeeId) {
            requestURL = requestURLPath1;
        }
        return requestURL
    }

    const requestUrl = getRequestUrl(status, payeeId, payerId);

    const requestData = {
        url: requestUrl,
        method: 'GET',
    };
    const successCallback = (response) => {
        dispatch({ type: 'getAllOrFilteredPaymentDataRequestSuccess', payload: response.data });
        handleRequestSuccessCallback && handleRequestSuccessCallback(response?.data)
    };
    const errorCallback = (error) => {
        dispatch({ type: 'getAllOrFilteredPaymentDataRequestFailed', payload: error });
        handleRequestFailedCallback && handleRequestFailedCallback(error)
    };
    http_request(requestData, successCallback, errorCallback);
};

/**
 * Update Incoming or Reject a Payment
 * @param {*} acceptOrRejectRequestDetails 
 * @param {*} handleSavePaymentInfoSuccessCallback 
 * @param {*} handleSavePaymentInfoFailedCallback 
 * @returns 
 */
export const requestUpdateAcceptOrRejectIncomingPayment =
    (acceptOrRejectRequestDetails, handleSavePaymentInfoSuccessCallback, handleSavePaymentInfoFailedCallback) =>
        async (dispatch, getState) => {
            dispatch({ type: 'putAcceptOrRejectIncomingPaymentRequest' });

            const { requestBody, payerId, paymentId, actionType } = acceptOrRejectRequestDetails;
            const requestData = {
                url: API_URL.payments.PUT_APPROVE_OR_REJECT_INCOMING_PAYMENT.replace(
                    '{payerId}',
                    payerId
                ).replace(
                    '{paymentId}',
                    paymentId
                ),
                method: 'PUT',
                data: requestBody,
            };
            const successCallback = (response) => {
                dispatch({ type: 'putAcceptOrRejectIncomingPaymentRequestSuccess', payload: response.data });
                const successMessage = `Payment is ${(actionType === 'ACCEPT_PAYMENT' || !actionType) ? 'Approved' : 'Rejected'} Successfully !`;
                Store.dispatch(handleNotification(successMessage, 'success'));
                handleSavePaymentInfoSuccessCallback && handleSavePaymentInfoSuccessCallback();
            };
            const errorCallback = (error) => {
                dispatch({ type: 'putAcceptOrRejectIncomingPaymentRequestFailed', payload: error });
                let message = `Payment ${(actionType === 'ACCEPT_PAYMENT' || !actionType) ? 'Approval' : 'Reject'} is Failed !`;
                if (error?.response?.status === 500) {
                    message = error?.response?.data?.message || 'Please Check Your Network Connection !';
                }
                Store.dispatch(handleNotification(message, 'error'));
                handleSavePaymentInfoFailedCallback && handleSavePaymentInfoFailedCallback();
            };
            http_request(requestData, successCallback, errorCallback);
        };
