import React, { useState, useEffect } from 'react';
import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { gridSpacing } from "store/constant";
import MainCard from 'ui-component/cards/MainCard';
import FormTopContainer from 'ui-component/dashboard/FormTopContainer';

import { ReactComponent as BlockActionIcon } from 'assets/images/icons/block-action.svg'
import BlockIcon from '@mui/icons-material/Block';

const OrganizationDetails = () => {
    //states
    const [organizationDetailDataPagination, setOrganizationDetailDataPagination] = useState({
        liabilityData: []
    })

    /**
     * Handle On Action click
     * @param {MouseEvent} event 
     */
     const handleActionClick = (event) => {
        console.log("Action Clicked");
    }

    /**
     * Handle On Click Block All
     * @param {MouseEvent} event 
     */
     const handleOnClickBlockAll = (event) => {
        console.log("Block All clicked");
    }
    //liability data table columns
    const organizationsDataColumns = [
        {
            field: 'organizationName',
            headerName: 'Organization',
            flex: 1
        },
        {
            field: 'dateCreated',
            headerName: 'Date Created',
            flex: 1
        },
        {
            field: 'lastAction',
            headerName: 'Last Action',
            flex: 1
        },
        {
            field: 'action',
            flex: 1,
            headerName: (
                <>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item xs='auto'>
                            <Typography
                                sx={theme=>({
                                    fontWeight: theme.typography.fontWeightMedium,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                })}
                            >
                                Action
                            </Typography>
                        </Grid>
                        <Grid item xs='auto'>
                            <Button
                                id='blockAllOrganizationsBtn'
                                onClick={handleOnClickBlockAll}
                                startIcon={<BlockIcon />}
                                sx={theme=>({
                                    color: theme.palette.grey?.[400],
                                    backgroundColor: theme.palette.grey?.[300],
                                    '&:hover': {
                                        color: theme.palette.grey?.[400],
                                        backgroundColor: theme.palette.grey?.[300],
                                    }
                                })}
                            >
                                Block All
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ),
            // flex: 1,
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    key={Math.random()}
                    id='blockOrganization'
                    icon={<SvgIcon viewBox='0 0 15 15' component={BlockActionIcon} />}
                    onClick={handleActionClick}
                    label='Block Organization'
                />
            ]
        }
    ]
    /**
     * Handle get organization data
     */
    const handleGetOrganizationDetailsData = () => {
        setOrganizationDetailDataPagination(prev=>({
            ...prev,
            liabilityData: [
                {
                    organizationId: 1,
                    organizationName: 'Gensler',
                    dateCreated: '2022-12-06',
                    lastAction: '2022-12-07',
                },
                {
                    organizationId: 2,
                    organizationName: 'Gensler',
                    dateCreated: '2022-12-06',
                    lastAction: '2022-12-07',
                },
                {
                    organizationId: 3,
                    organizationName: 'Gensler',
                    dateCreated: '2022-12-06',
                    lastAction: '2022-12-07',
                },
                {
                    organizationId: 4,
                    organizationName: 'Gensler',
                    dateCreated: '2022-12-06',
                    lastAction: '2022-12-07',
                },
                {
                    organizationId: 5,
                    organizationName: 'Gensler',
                    dateCreated: '2022-12-06',
                    lastAction: '2022-12-07',
                },
            ]
        }))
    }
    /**
     * Effect
     */
    useEffect(() => {
        handleGetOrganizationDetailsData()
    }, [])
    return (
        <Grid container spacing={gridSpacing}>
            <Grid container item xs={12}>
                <MainCard>
                    <FormTopContainer title={"Organization Details"} />
                </MainCard>
            </Grid>
            <Grid container item xs={12}>
                <MainCard>
                    <DataGrid
                        rows={organizationDetailDataPagination.liabilityData}
                        columns={organizationsDataColumns}
                        getRowId={(row) => row?.organizationId}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection={false}
                        sx={theme => ({ height: '60vh', backgroundColor: theme.palette.white })}
                        // components={{
                        // 	Pagination: GridPagination
                        // }}
                        // componentsProps={{
                        //   pagination: {
                        //     backIconButtonProps: {
                        //       color: 'primary',
                        //       sx: (theme) => ({
                        //         backgroundColor: theme.palette.green?.[100]
                        //       })
                        //     },
                        //     nextIconButtonProps: {
                        //       color: 'primary',
                        //       sx: (theme) => ({
                        //         backgroundColor: theme.palette.green?.[100]
                        //       })
                        //     }
                        //   }
                        // }}
                    />
                </MainCard>
            </Grid>
        </Grid>
    );
}

export default OrganizationDetails;