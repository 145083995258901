import { createReducer } from '@reduxjs/toolkit';

const initialState = {

    // get all/[filtered]  payments
    isGetFilteredPaymentsRequestLoading: false,
    paymentsGetRequestFilters: null,
    filteredPaymentsData: null,
    filteredPaymentsDataFailed: null,

    // accept/reject incoming payment
    isPutAcceptOrRejectIncomingPaymentRequestLoading: false,
    acceptOrRejectIncomingPaymentRequestSuccessData: null,
    acceptOrRejectIncomingPaymentRequestFailedData: null,

};

export const paymentsReducer = createReducer(initialState, {

    //get all [filtered] payments
    getAllOrFilteredPaymentDataRequest: (state, action) => {
        state.isGetFilteredPaymentsRequestLoading = true;
        state.filteredPaymentsData = null;
        state.filteredPaymentsDataFailed = {};

        state.paymentsGetRequestFilters = action.payload?.paymentsGetRequestFilters;
    },
    getAllOrFilteredPaymentDataRequestSuccess: (state, action) => {
        state.isGetFilteredPaymentsRequestLoading = false;
        state.filteredPaymentsData = action.payload;
        state.filteredPaymentsDataFailed = {};
    },
    getAllOrFilteredPaymentDataRequestFailed: (state, action) => {
        state.isGetFilteredPaymentsRequestLoading = false;
        state.filteredPaymentsData = null;
        state.filteredPaymentsDataFailed = action.payload;
    },


    //accept/reject incoming payments[client => advisor]
    putAcceptOrRejectIncomingPaymentRequest: (state) => {
        state.isPutAcceptOrRejectIncomingPaymentRequestLoading = true;
        state.acceptOrRejectIncomingPaymentRequestSuccessData = null;
        state.acceptOrRejectIncomingPaymentRequestFailedData = null;
    },
    putAcceptOrRejectIncomingPaymentRequestSuccess: (state, action) => {
        state.isPutAcceptOrRejectIncomingPaymentRequestLoading = false;
        state.acceptOrRejectIncomingPaymentRequestSuccessData = action.payload;
        state.acceptOrRejectIncomingPaymentRequestFailedData = null;
    },
    putAcceptOrRejectIncomingPaymentRequestFailed: (state, action) => {
        state.isPutAcceptOrRejectIncomingPaymentRequestLoading = false;
        state.acceptOrRejectIncomingPaymentRequestSuccessData = null;
        state.acceptOrRejectIncomingPaymentRequestFailedData = action.payload;
    },
});
