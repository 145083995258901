import { createReducer } from '@reduxjs/toolkit';
import config from 'config';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

export const customization = createReducer(initialState, {

    customization_SET_MENU: (state, action) => {
        return {
            ...state,
            opened: action.opened
        };
    },
    customization_MENU_TOGGLE: (state, action) => {
        return {
            ...state,
            opened: action.opened
        };
    },
    customization_MENU_OPEN: (state, action) => {
        return {
            ...state,
            isOpen: [action.url]
        };
    },
    customization_SET_FONT_FAMILY: (state, action) => {
        return {
            ...state,
            fontFamily: action.fontFamily
        };
    },
    customization_SET_BORDER_RADIUS: (state, action) => {
        return {
            ...state,
            borderRadius: action.borderRadius
        };
    },

});
