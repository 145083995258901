import React, { useEffect, useState } from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router-dom';

import MainCard from 'ui-component/cards/MainCard';

import FormTopContainer from 'ui-component/dashboard/FormTopContainer';
import AdvisorProfileCard from 'ui-component/profile/AdvisorProfileCard';
import { requestGetAllAdvisors } from 'redux/Actions/advisorProfileAction';
import { useDispatch, useSelector } from 'react-redux';
import PaginationControlled from 'ui-component/PaginationControlled';
import LoadingSpinner from 'ui-component/LoadingSpinner';

const AdvisorsSettings = () => {
  /**
   * Uses react-router-dom navigation
   */
  const navigate = useNavigate();
  /**
   * Redux Integrations
   */
  const dispatch = useDispatch();
  //advisor profile store
  const advisorProfileReducer = useSelector((state) => state?.advisorProfileReducer);
  const isGetAllAdvisorsRequestLoading = advisorProfileReducer?.isGetAllAdvisorsRequestLoading;
  const advisorsGetAllRequestSuccessData = advisorProfileReducer?.advisorsGetAllRequestSuccessData;

  const [paginationData, setPaginationData] = useState({
    data: [],
    pageNo: 1,
    totalElements: 0,
    pageSize: 8,
  });
  /**
   * Handle change page
   * @param {*} event
   * @param {*} pageNo
   */
  const handlePaginationOnChange = (event, pageNo) => {
    let tempAdvisorsData =
      (Array.isArray(advisorsGetAllRequestSuccessData) && advisorsGetAllRequestSuccessData) || [];

    tempAdvisorsData = tempAdvisorsData.map((singleAdvisorDetail) => ({
      ...singleAdvisorDetail,
      advisorName:
        singleAdvisorDetail?.firstName && singleAdvisorDetail?.lastName
          ? `${singleAdvisorDetail?.firstName} ${singleAdvisorDetail?.lastName}`
          : singleAdvisorDetail?.firstName && !singleAdvisorDetail?.lastName
          ? singleAdvisorDetail?.firstName
          : singleAdvisorDetail?.lastName,
    }));
    setPaginationData((prev) => ({
      ...prev,
      pageNo: pageNo,
      data: tempAdvisorsData.slice((pageNo - 1) * prev.pageSize, pageNo * prev.pageSize),
    }));
  };
  /**
   * Handle Navigation
   * @param {*} event
   * @param {*} data
   */
  const handleGotoAdvisorProfile = (event, data) => {
    navigate('/main/settings/advisorsSettings/advisorDetails', {
      state: {
        advisorData: data,
      },
    });
  };
  /**
   * Get All Account Owners
   */
  const handleGetAllAdvisors = () => {
    const handleGetAllAdvisorRequestSuccessCallback = (response) => {
      if (!!response?.length && Array.isArray(response)) {
        const tempAdvisorListData = response.map((singleAdvisorDetail) => ({
          ...singleAdvisorDetail,
          advisorName:
            singleAdvisorDetail?.firstName && singleAdvisorDetail?.lastName
              ? `${singleAdvisorDetail?.firstName} ${singleAdvisorDetail?.lastName}`
              : singleAdvisorDetail?.firstName && !singleAdvisorDetail?.lastName
              ? singleAdvisorDetail?.firstName
              : singleAdvisorDetail?.lastName,
        }));
        setPaginationData((prev) => ({
          ...prev,
          pageNo: 1,
          data: tempAdvisorListData.slice(0, prev.pageSize),
          totalElements: tempAdvisorListData?.length || 0,
        }));
      }
    };
    dispatch(requestGetAllAdvisors(handleGetAllAdvisorRequestSuccessCallback));
  };
  /**
   * Effect on mount
   */
  useEffect(() => {
    handleGetAllAdvisors();
  }, []);
  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Advisors'} />
        </MainCard>
      </Grid>

      <Grid item xs={12}>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={gridSpacing}>
                    {!!paginationData?.data?.map &&
                      paginationData.data.map((data, index) => (
                        <Grid item xs={6} md={3} lg={3}>
                          <AdvisorProfileCard
                            profileData={data}
                            openAdvisorProfile={(event) => handleGotoAdvisorProfile(event, data)}
                          />
                        </Grid>
                      ))}
                    {!!paginationData?.data?.length && (
                      <Grid item xs={12}>
                        <PaginationControlled
                          pageNo={paginationData?.pageNo}
                          pageSize={paginationData?.pageSize}
                          totalElements={paginationData?.totalElements}
                          handlePageChange={handlePaginationOnChange}
                        />
                      </Grid>
                    )}
                    {!paginationData?.data?.length && (
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          flex={1}
                          alignItems="center"
                          justifyContent="center"
                          py={4}
                        >
                          <Typography color="red.750" fontSize="1rem" fontWeight="fontWeightMedium">
                            No Records !
                          </Typography>
                        </Stack>{' '}
                      </Grid>
                    )}
                    {!!isGetAllAdvisorsRequestLoading && (
                      <LoadingSpinner isLoadingSpinner={isGetAllAdvisorsRequestLoading} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default AdvisorsSettings;
