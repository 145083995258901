import React from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import souffle_logo from 'assets/logo/souffle_logo.gif';

const Logo = (props) => {
  const { width } = props;
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <img src={souffle_logo} alt={'souffle-logo'} style={{ width: width || 350 }} />
  );
};

export default Logo;
