import { makeStyles } from 'tss-react/mui'

export const useAdvisorDetailStyles = makeStyles()((theme) => ({
    advisorPersonalDetailInputLabel: {
        color: theme.palette.blue?.[400]
    },
    advisorPaymentBlockSwitch: {
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.green?.[600] : theme.palette.green?.[400],
                    opacity: 1,
                    border: 0
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5
                }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[500]
                        : theme.palette.grey[600]
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
            }
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500
            })
        }
    }
}))
