import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //login
    isGetDashboardStatisticRequestLoading: false,
    adminDashboardStaticsRequestSuccessData: {},
    adminDashboardStaticsRequestFailedData: {},
};

export const adminDashboardReducer = createReducer(initialState, {
    //admin dashboard statistics
    getAdminDashboardStatisticsRequest: (state) => {
        state.isGetDashboardStatisticRequestLoading = true;
        state.adminDashboardStaticsRequestSuccessData = {};
        state.adminDashboardStaticsRequestFailedData = {};
    },
    getAdminDashboardStatisticsRequestSuccess: (state, action) => {
        state.isGetDashboardStatisticRequestLoading = false;
        state.adminDashboardStaticsRequestSuccessData = action.payload;
        /***
        {
            "activeAdvisors": 10,
            "inactiveAdvisors": 0,
            "activeClients": 4,
            "inactiveClients": 0,
            "activeLeads": 2,
            "inactiveLeads": 0
        } */
        state.adminDashboardStaticsRequestFailedData = {};
    },
    getAdminDashboardStatisticsRequestFailed: (state, action) => {
        state.isGetDashboardStatisticRequestLoading = false;
        state.adminDashboardStaticsRequestSuccessData = {};
        state.adminDashboardStaticsRequestFailedData = action.payload;
    },

    //TODO dashboard notification list 

});
