import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';
import { useLottie } from 'lottie-react';

import MainCard from 'ui-component/cards/MainCard';

const TypoType5 = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.dark.main,
  fontWeight: 550,
}));

const NavigationCard = (props) => {
  const { title, img, path } = props;

  let navigate = useNavigate();

  const style = {
    height: 100,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: img,
  };
  const { View } = useLottie(defaultOptions, style);

  return (
    <MainCard style={{ cursor: 'pointer' }} boxShadow backgroundColor>
      <Grid
        container
        item
        xs={12}
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        // alignItems={"flex-end"}
        onClick={() => navigate(`/main/${path}`)}
      >
        <Grid item xs={12}>
          <Box style={{ height: 120 }}>{View}</Box>
        </Grid>
        <Grid container item xs={12} alignContent={'center'} justifyContent={'center'}>
          <TypoType5>{title}</TypoType5>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default NavigationCard;
