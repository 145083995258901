import { FormControl, InputLabel, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const InputField = (props) => {
  const {
    isFormControlField,
    formControlClass,
    formControlVariant,
    formControlProps,
    inputLabel,
    ...fieldProps
  } = props;

  return isFormControlField ? (
    <FormControl
      className={formControlClass}
      variant={formControlVariant || 'standard'}
      fullWidth
      {...(formControlProps || {})}
    >
      <InputLabel shrink variant="standard" htmlFor={fieldProps?.id || 'input-field'}>
        {inputLabel}
      </InputLabel>
      <TextField sx={{ mt: '1.25rem' }} {...fieldProps} />
    </FormControl>
  ) : (
    <TextField fullWidth {...fieldProps} />
  );
};
InputField.defaultProps = {
  formControlProps: { fullWidth: true },
};
InputField.propTypes = {
  isFormControlField: PropTypes.bool,
  formControlClass: PropTypes.any,
  formControlVariant: PropTypes.string,
  formControlProps: PropTypes.any,
  inputLabel: PropTypes.string,
};

export default InputField;
