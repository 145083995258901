import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Tabs, Tab, Typography, Box } from '@mui/material';

import { gridSpacing } from 'store/constant';
import Lottie from 'lottie-react';
import MainCard from 'ui-component/cards/MainCard';
import FormTopContainer from 'ui-component/dashboard/FormTopContainer';
import { useCommonStyles } from 'styles/styles';

import * as emailSentAnimationData from 'assets/animation/email-notification.json';
import * as emailSendAnimationData from 'assets/animation/email-send.json';
import * as noteBookWritingAnimationData from 'assets/animation/notebook-writing.json';
import * as DashboardAnimationData from 'assets/animation/dashboard-elements.json';

import Compose from './component/Compose';
import Inbox from './component/Inbox';
import SendItems from './component/SendItems';
import Templates from './component/Templates';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NotificationManagement = () => {
  const { classes: commonClasses } = useCommonStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Notifications'} />
        </MainCard>
      </Grid>

      <Grid container item xs={12}>
        <Card className={commonClasses.mainContainer_Wrap}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab
              icon={
                <Box style={{ height: 60, width: 60 }}>
                  <Lottie animationData={noteBookWritingAnimationData} />
                </Box>
              }
              label="Compose"
              {...a11yProps(0)}
            />
            <Tab
              icon={
                <Box style={{ height: 60, width: 60 }}>
                  <Lottie animationData={emailSentAnimationData} />
                </Box>
              }
              label="Inbox"
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <Box style={{ height: 60, width: 60 }}>
                  <Lottie animationData={emailSendAnimationData} />
                </Box>
              }
              label="Sent Items"
              {...a11yProps(2)}
            />
            <Tab
              icon={
                <Box style={{ height: 60, width: 60 }}>
                  <Lottie animationData={DashboardAnimationData} />
                </Box>
              }
              label="Templates"
              {...a11yProps(3)}
            />
          </Tabs>
          <TabPanel value={value} index={0} style={{ width: '100%' }}>
            <Compose />
          </TabPanel>
          <TabPanel value={value} index={1} style={{ width: '100%' }}>
            <Inbox />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ width: '100%' }}>
            <SendItems />
          </TabPanel>
          <TabPanel value={value} index={3} style={{ width: '100%' }}>
            <Templates />
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotificationManagement;
