import { useState } from 'react';
import { ApprovalOutlined, CancelOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import CommonModal from 'common/material/CommonModal';
import InputField from 'common/material/InputField';
import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';
import LoadingSpinner from 'ui-component/LoadingSpinner';

import { requestUpdateAcceptOrRejectIncomingPayment } from 'redux/Actions/paymentsAction';

const ConfirmationForm = (props) => {
  const { confirmationData, handleDialogOpened, handleApproveRejectSuccessCallback } = props;
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  //payment store
  const paymentsReducer = useSelector((state) => state?.paymentsReducer);
  const isPutAcceptOrRejectIncomingPaymentRequestLoading =
    paymentsReducer?.isPutAcceptOrRejectIncomingPaymentRequestLoading;
  /**
   * Uses app theme
   */
  const { classes: commonClasses } = useCommonStyles();

  //states
  const [approveRejectFormData, setApproveRejectFormData] = useState({
    comment: '',
    errors: {
      comment: '',
    },
  });
  /**
   * Handle field change
   * @param {*} event
   * @param {*} fieldType
   */
  const handleFieldChange = (event, fieldType) => {
    setApproveRejectFormData((prev) => ({
      ...prev,
      [fieldType]: event?.target?.value || '',
    }));
  };
  /**
   * Handle form reset
   * @param {*} event
   */
  const handleFormReset = (event) => {
    setApproveRejectFormData({
      comment: '',
      errors: {
        comment: '',
      },
    });
  };
  /**
   * On Click submit accept or reject payment
   */
  const handleAcceptOrDeclinePayment = () => {
    let confirmingData = confirmationData?.data;
    let confirmingAction = confirmationData?.actionType;
    let comment = approveRejectFormData?.comment;

    const acceptOrRejectPaymentRequestDetails = {
      requestBody: {
        comment,
        status:
          confirmingAction === 'ACCEPT_PAYMENT'
            ? 'Approved'
            : confirmingAction === 'REJECT_PAYMENT' && 'Rejected', //["Paid", "Pending", "Refunded", "Approved", "Rejected"],
      },
      paymentId: confirmingData?._id,
      payerId: confirmingData?.payerId,
      actionType: confirmingAction,
    };
    const handleRequestSuccessCallback = (response) => {
      handleApproveRejectSuccessCallback && handleApproveRejectSuccessCallback();
      handleDialogOpened && handleDialogOpened(false);
    };
    dispatch(
      requestUpdateAcceptOrRejectIncomingPayment(
        acceptOrRejectPaymentRequestDetails,
        handleRequestSuccessCallback
      )
    );
  };

  return (
    <Grid container spacing={gridSpacing}>
      {!!isPutAcceptOrRejectIncomingPaymentRequestLoading && (
        <LoadingSpinner isLoadingSpinner={!!isPutAcceptOrRejectIncomingPaymentRequestLoading} />
      )}
      <Grid item xs={12}>
        <Typography variant="caption">Please confirm your action !</Typography>
      </Grid>
      <Grid item xs={12}>
        <InputField
          label="Comment"
          value={approveRejectFormData?.comment || ''}
          onChange={(event) => handleFieldChange(event, 'comment')}
          multiline
          minRows={3}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={gridSpacing} justifyContent="flex-end">
          <Button
            id="resetFormBtn"
            variant="outlined"
            className={commonClasses.clearBtn}
            onClick={handleFormReset}
          >
            Reset
          </Button>
          <LoadingButton
            id={'sign-btn'}
            disableElevation
            disabled={false}
            size="large"
            variant="contained"
            loadingPosition="end"
            loading={false}
            className={
              confirmationData?.actionType && confirmationData.actionType === 'ACCEPT_PAYMENT'
                ? commonClasses.saveBtn
                : commonClasses.redBtnSmall
            }
            endIcon={
              confirmationData?.actionType && confirmationData.actionType === 'ACCEPT_PAYMENT' ? (
                <ApprovalOutlined />
              ) : (
                <CancelOutlined />
              )
            }
            onClick={handleAcceptOrDeclinePayment}
          >
            {confirmationData?.actionType === 'ACCEPT_PAYMENT'
              ? 'Approve Payment'
              : confirmationData?.actionType === 'REJECT_PAYMENT' && 'Reject Payment'}
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

const PaymentApprovalRejectConfirmationModal = (props) => {
  const { isOpened, confirmationData, handleDialogOpened, handleApproveRejectSuccessCallback } =
    props;

  return (
    <CommonModal
      open={isOpened}
      dialogTitle={`Please Confirm Payment ${
        confirmationData?.actionType && confirmationData.actionType === 'ACCEPT_PAYMENT'
          ? 'Approval '
          : 'Decline'
      }`}
      dialogContent={
        <ConfirmationForm
          confirmationData={confirmationData}
          handleDialogOpened={handleDialogOpened}
          handleApproveRejectSuccessCallback={handleApproveRejectSuccessCallback}
        />
      }
      handleDialogOpened={handleDialogOpened}
      fullWidth
    />
  );
};

export default PaymentApprovalRejectConfirmationModal;
