/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        action: {
          hover: theme.colors?.blue150,
          selected: theme.colors?.blue175
        },
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        blue: {
            50: theme.colors?.blue50,
            150: theme.colors?.blue150,
            175: theme.colors?.blue175,
            300: theme.colors?.blue300,
            400: theme.colors?.blue400,
            500: theme.colors?.blue500,
            600: theme.colors?.blue600,
            700: theme.colors?.blue700,
            750: theme.colors?.blue750,
        },
        green: {
            main: theme.colors?.green200,
            100: theme.colors?.green100,
            200: theme.colors?.green200,
            400: theme.colors?.green400,
            450: theme.colors?.green450,
            500: theme.colors?.green500,
            600: theme.colors?.green600
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark,
            400: theme.colors?.orange400
        },
        grey: {
            50: theme.colors?.grey50,
            60: theme.colors?.grey60,
            100: theme.colors?.grey100,
            200: theme.colors?.grey200,
            300: theme.colors?.grey300,
            325: theme.colors?.grey325,
            350: theme.colors?.grey350,
            400: theme.colors?.grey400,
            500: theme.darkTextSecondary,
            550: theme.colors?.grey550,
            600: theme.heading,
            650: theme.colors?.grey650,
            700: theme.darkTextPrimary,
            800: theme.colors?.grey800,
            900: theme.textDark
        },
        red: {
            main: theme.colors?.red750,
            400: theme.colors?.red400,
            700: theme.colors?.red700,
            750: theme.colors?.red750,
            800: theme.colors?.red800,
        },
        yellow: {
            700: theme.colors?.yellow700
        },
        purple: {
            700: theme.colors?.purple700
        },

        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100
        },
        background: {
            paper: theme.paper,
            default: theme.backgroundDefault
        }
    };
}
