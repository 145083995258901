import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, dropDownValue, theme) {
  return {
    fontWeight:
      dropDownValue.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DropDown = (props) => {
  const {
    optionData,
    isMultiple,
    placeholder,
    dropdownValueKey,
    dropdownValueLabelKey,
    handleDropDownChangeCallback,
    isShrinkLabel,
    formLabel,
    formControlProps,
    ...otherProps
  } = props;
  const theme = useTheme();
  const [dropDownValue, setDropDownValue] = React.useState('');

  return (
    <FormControl sx={{ textAlign: 'left' }} {...(formControlProps || {})}>
      {(otherProps?.label || formLabel) && (
        <InputLabel shrink={isShrinkLabel} id="demo-multiple-name-label" variant="outlined">
          {otherProps?.label || formLabel}
        </InputLabel>
      )}
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple={isMultiple || false}
        value={dropDownValue}
        input={<OutlinedInput shrink={isShrinkLabel} label={otherProps?.label || formLabel} />}
        MenuProps={MenuProps}
        displayEmpty
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (
              <Typography sx={(theme) => ({ color: theme.palette.text.secondary })}>
                {placeholder || 'Please Select'}
              </Typography>
            );
          } else if (typeof selected === 'object' && typeof selected?.id !== 'undefined') {
            return selected?.[dropdownValueLabelKey || 'label'];
          } else {
            return selected;
          }
        }}
        sx={{ mt: formLabel && '1.25rem' }}
        {...otherProps}
      >
        {optionData?.map &&
          optionData.map((option, optionIndex) => (
            <MenuItem
              key={option?.[dropdownValueKey || 'id']}
              value={option?.[dropdownValueKey || 'id']}
              style={getStyles(option?.[dropdownValueLabelKey || 'label'], dropDownValue, theme)}
            >
              {option?.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

DropDown.defaultProps = {
  isShrinkLabel: true,
  formControlProps: {
    fullWidth: true,
  },
};
DropDown.propTypes = {
  optionData: PropTypes.array,
  isMultiple: PropTypes.bool,
  placeholder: PropTypes.string,
  dropdownValueKey: PropTypes.string,
  dropdownValueLabelKey: PropTypes.string,
  handleDropDownChangeCallback: PropTypes.func,
  isShrinkLabel: PropTypes.any,
  formLabel: PropTypes.string,
  formControlProps: PropTypes.object,
};

export default DropDown;
