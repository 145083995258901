// document template tags 
export const documentTagData = [
  {
    label: 'Advisor Profile',
    data: [
      {
        label: 'Advisor Email Address',
        key: 'advisorEmailAddress',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Advisor Email Address</span>',
      },
      {
        label: 'Advisor First Name',
        key: 'advisorFirstName',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Advisor First Name</span>',
      },
      {
        label: 'Advisor Surname',
        key: 'advisorSurname',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Advisor Surname</span>',
      },
      {
        label: 'Advisor Profile Picture',
        key: 'advisorProfilePicture',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Advisor Profile Picture</span>',
      },
    ],
  },
  {
    label: 'Client Details',
    data: [
      {
        label: 'Client Primary Email Address',
        key: 'clientPrimaryEmailAddress',
        value:
          '<span aria-label="replacer text" style="background:#2F89d2; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Primary Email Address</span>',
      },
      {
        label: 'Client Primary Mobile Number',
        key: 'clientPrimaryMobileNumber',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Primary Mobile Number</span>',
      },
      {
        label: 'Client First Name',
        key: 'clientFirstName',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client First Name</span>',
      },
      {
        label: 'Client Surname',
        key: 'clientSurname',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Surname</span>',
      },
      {
        label: 'Client Title',
        key: 'clientTitle',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Title</span>',
      },
      {
        label: 'Client Australian Resident',
        key: 'clientAustralianResident',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Australian Resident</span>',
      },
      {
        label: 'Client Postal Address',
        key: 'clientPostalAddress',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Postal Address</span>',
      },
      {
        label: 'Client Residential Address',
        key: 'clientResidentialAddress',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Residential Address</span>',
      },
      {
        label: 'Client Residential Address',
        key: 'clientResidentialAddress',
        value:
          '<span aria-label="replacer text" style="background:#2F89C8; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client Residential Address</span>',
      },
    ],
  },
  {
    label: 'Client Partner Details',
    data: [
      {
        label: 'Client has a partner',
        key: 'clientHasPartner',
        value:
          '<span aria-label="replacer text" style="background:#2F89d2; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Client has a partner</span>',
      },
      {
        label: "Client's Partner First Name",
        key: 'clientPartnerFirstName',
        value:
          '<span aria-label="replacer text" style="background:#2F89d2; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Partner First Name</span>',
      },
      {
        label: "Client's Partner Title",
        key: 'clientPartnerTitle',
        value:
          '<span aria-label="replacer text" style="background:#2F89d2; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Partner Title</span>',
      },
      {
        label: "Client's Partner Surname",
        key: 'clientPartnerSurname',
        value:
          '<span aria-label="replacer text" style="background:#2F89d2; color:white; padding: 0 0.875rem; border-radius: 0.25rem; user-select:text" contenteditable="false">Partner Surname</span>',
      },
    ],
  },
];

/**
 * Get Client Address
 * @returns clientAddress
 */
const getClientAddressText = (addressObj) => {
  let clientAddress = '';
  if (typeof addressObj === 'object' && addressObj?.address) {
    let addresses = [
      addressObj?.address,
      addressObj?.suburb,
      addressObj?.state,
      addressObj?.postcode,
    ];
    addresses = addresses.filter((addressDetail) => !!addressDetail);
    clientAddress = addresses.join(', ');
  }
  return clientAddress;
};
/**
 * Get replaceable string for the tag
 * @param {*} tagDetail 
 * @param {*} availableData 
 * @returns {string} replacement
 */
const getReplacementValue = (tagDetail, availableData) => {
  const searchKey = tagDetail?.key;
  let replacementText = '';
  switch (searchKey) {
    //_advisor
    case 'advisorEmailAddress':
      replacementText = availableData?.advisorData?.emailAddress;
      break;
    case 'advisorFirstName':
      replacementText = availableData?.advisorData?.firstName;
      break;
    case 'advisorSurname':
      replacementText = availableData?.advisorData?.surname;
      break;
    case 'advisorProfilePicture':
      replacementText =
        availableData?.advisorData?.profilePictureUrl &&
        `<img src={${availableData?.advisorData?.profilePictureUrl}} />`;
      break;
    //_client contact
    case 'clientPrimaryEmailAddress':
      replacementText = availableData?.clientData?.primaryEmailAddress;
      break;
    case 'clientPrimaryMobileNumber':
      replacementText = availableData?.clientData?.primaryMobileNumber;
      break;
    case 'clientFirstName':
      replacementText = availableData?.clientData?.firstName;
      break;
    case 'clientSurname':
      replacementText = availableData?.clientData?.surname;
      break;
    case 'clientTitle':
      replacementText = availableData?.clientData?.title;
      break;
    case 'clientAustralianResident':
      replacementText = availableData?.clientData?.isAustralianResident && 'Australian Resident';
      break;
    case 'clientPostalAddress':
      replacementText = getClientAddressText(availableData?.clientData?.postalAddress);
      break;
    case 'clientResidentialAddress':
      replacementText = getClientAddressText(availableData?.clientData?.residentialAddress);
      break;
    //_client partner
    case 'clientHasPartner':
      replacementText = availableData?.clientData?.hasPartner && 'Client has partner';
      break;
    case 'clientPartnerFirstName':
      replacementText =
        availableData?.clientData?.hasPartner && availableData?.clientData?.partner?.firstName;
      break;
    case 'clientPartnerTitle':
      replacementText =
        availableData?.clientData?.hasPartner && availableData?.clientData?.partner?.title;
      break;
    case 'clientPartnerSurname':
      replacementText =
        availableData?.clientData?.hasPartner && availableData?.clientData?.partner?.surname;
      break;
    default:
      break;
  }
  return (replacementText && replacementText + ' &nbsp;') || '';
};
/**
 * Handle replacement of content's tags
 * @param {*} contentText 
 * @param {*} availableData {advisorData, clientData}
 * @returns {string} content
 */
export const handleContentReplacement = (contentText, availableData) => {
  let replacement = contentText;
  const allTags = documentTagData.reduce((prevValue, currentTagDetail) => {
    let tags = [...prevValue];
    if (Array.isArray(currentTagDetail?.data)) {
      tags = [...tags, ...currentTagDetail.data];
    }
    return tags;
  }, []);

  if (typeof replacement === 'string') {
    allTags.forEach((tagDetail) => {
      if (replacement.includes(tagDetail.value)) {
        const replaceText = getReplacementValue(tagDetail, availableData);
        replacement = replacement.replaceAll(tagDetail.value, replaceText);
      }
    });
  }

  return replacement;
};
