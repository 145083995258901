import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * send bulk email
 * @param {object} requestBody
 * @returns {async function}
 */
export const requestBulkEmail =
  (requestBody, handlePostEmailSuccessCallback = null, handlePostEmailFailedCallback = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'postBulkEmailRequest' });

    const requestData = {
      url: API_URL.email.POST_BULK_SENT_EMAIL,
      method: 'POST',
      data: requestBody,
    };
    const successCallback = (response) => {
      dispatch({ type: 'postBulkEmailRequestSuccess', payload: response.data });
      Store.dispatch(handleNotification(`Email sent successfully.`, 'success'));
      handlePostEmailSuccessCallback && handlePostEmailSuccessCallback();
    };
    const errorCallback = (error) => {
      dispatch({ type: 'postBulkEmailRequestFailed', payload: error });
      let message = 'Email failed to send.';
      if (error?.response?.status === 500) {
        message = error?.response?.data?.message || 'Please Check Details !';
      }
      Store.dispatch(handleNotification(message, 'error'));
      handlePostEmailFailedCallback && handlePostEmailFailedCallback();
    };
    http_request(requestData, successCallback, errorCallback);
  };
