import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //tax setting data request
  isGetAllTaxSettingRequestLoading: false,
  taxSettingData: [],
  taxSettingFailedData: {},

  //find tax setting
  isFindTaxSettingRequestLoading: false,
  promoCodeCreateRequestSuccessData: {},
  promoCodeCreateRequestFailedData: {},

  //search tax settings
  isSearchTaxSettingsRequestLoading: false,
  searchTaxSettingsRequestSuccessData: {},
  searchTaxSettingsRequestFailedData: {},

  //save tax settings
  isSaveTaxSettingRequestLoading: false,
  saveTaxSettingRequestSuccessData: {},
  saveTaxSettingRequestFailedData: {},

  //update tax setting
  isUpdateTaxSettingRequestLoading: false,
  updateTaxSettingRequestSuccessData: {},
  updateTaxSettingRequestFailedData: {},
};

export const taxSettingReducer = createReducer(initialState, {
  //get tax settings
  getAllTaxSettingsRequest: (state) => {
    state.isGetAllTaxSettingRequestLoading = true;
    state.taxSettingData = [];
    state.taxSettingFailedData = {};
  },
  getAllTaxSettingsSuccess: (state, action) => {
    state.isGetAllTaxSettingRequestLoading = false;
    state.taxSettingData = action.payload;
    state.taxSettingFailedData = {};
  },
  getAllTaxSettingsFailed: (state, action) => {
    state.isGetAllTaxSettingRequestLoading = false;
    state.taxSettingData = [];
    state.taxSettingFailedData = action.payload;
  },

  // post save tax settings
  findTaxSettingRequest: (state) => {
    state.isFindTaxSettingRequestLoading = true;
    state.promoCodeCreateRequestSuccessData = true;
    state.promoCodeCreateRequestFailedData = true;
  },
  findTaxSettingRequestSuccess: (state, action) => {
    state.isFindTaxSettingRequestLoading = false;
    state.promoCodeCreateRequestSuccessData = action.payload;
    state.promoCodeCreateRequestFailedData = {};
  },
  findTaxSettingRequestFailed: (state, action) => {
    state.isFindTaxSettingRequestLoading = false;
    state.promoCodeCreateRequestSuccessData = {};
    state.promoCodeCreateRequestFailedData = action.payload;
  },

  // search tax settings
  searchTaxSettingsRequest: (state) => {
    state.isSearchTaxSettingsRequestLoading = true;
    state.searchTaxSettingsRequestSuccessData = {};
    state.searchTaxSettingsRequestFailedData = {};
  },
  searchTaxSettingsRequestSuccess: (state, action) => {
    state.isSearchTaxSettingsRequestLoading = false;
    state.searchTaxSettingsRequestSuccessData = action.payload;
    state.searchTaxSettingsRequestFailedData = {};
  },
  searchTaxSettingsRequestFailed: (state, action) => {
    state.isSearchTaxSettingsRequestLoading = false;
    state.searchTaxSettingsRequestSuccessData = {};
    state.searchTaxSettingsRequestFailedData = action.payload;
  },

  //save tax setting
  saveTaxSettingRequest: (state) => {
    state.isSaveTaxSettingRequestLoading = true;
    state.saveTaxSettingRequestSuccessData = {};
    state.saveTaxSettingRequestFailedData = {};
  },
  saveTaxSettingRequestSuccess: (state, action) => {
    state.isSaveTaxSettingRequestLoading = false;
    state.saveTaxSettingRequestSuccessData = action.payload;
    state.saveTaxSettingRequestFailedData = {};
  },
  saveTaxSettingRequestFailed: (state, action) => {
    state.isSaveTaxSettingRequestLoading = false;
    state.saveTaxSettingRequestSuccessData = {};
    state.saveTaxSettingRequestFailedData = action.payload;
  },

  //update tax setting
  updateTaxSettingRequest: (state) => {
    state.isUpdateTaxSettingRequestLoading = true;
    state.updateTaxSettingRequestSuccessData = {};
    state.updateTaxSettingRequestFailedData = {};
  },
  updateTaxSettingRequestSuccess: (state, action) => {
    state.isUpdateTaxSettingRequestLoading = false;
    state.updateTaxSettingRequestSuccessData = action.payload;
    state.updateTaxSettingRequestFailedData = {};
  },
  updateTaxSettingRequestFailed: (state, action) => {
    state.isUpdateTaxSettingRequestLoading = false;
    state.updateTaxSettingRequestSuccessData = {};
    state.updateTaxSettingRequestFailedData = action.payload;
  },
});
