import React from 'react';

import { Grid } from '@mui/material';

import { gridSpacing } from 'store/constant';

import MainCard from 'ui-component/cards/MainCard';
import NavigationCard from 'ui-component/dashboard/NavigationCard';

import * as investmentAnimationData from 'assets/animation/capital-investment.json';

import FormTopContainer from 'ui-component/dashboard/FormTopContainer';

const AdvisorPortalSettings = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Advisor Portal Setting'} />
        </MainCard>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <NavigationCard
                      title={'Tax Setting'}
                      path={'settings/taxSettings'}
                      img={investmentAnimationData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorPortalSettings;
