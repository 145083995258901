import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    //get all advisor [account owner]
    isGetAllAdvisorsRequestLoading: false,
    advisorsGetAllRequestSuccessData: {},
    advisorsGetAllRequestFailedData: {},

    isGetFindAdvisorDetailByIdRequestLoading: false,
    selectedAdvisorDetailSuccessData: {},
    selectedAdvisorDetailFailedData: {},

    isPatchAllowOrBlockAccessToAdvisorPortalRequestLoading: false,
    allowOrBlockAccessToAdvisorPortalSuccessData: {},
    allowOrBlockAccessToAdvisorPortalFailedData: {},
};

export const advisorProfileReducer = createReducer(initialState, {
    //advisor list statistics
    getAllAdvisorsRequest: (state) => {
        state.isGetAllAdvisorsRequestLoading = true;
        state.advisorsGetAllRequestSuccessData = {};
        state.advisorsGetAllRequestFailedData = {};
    },
    getAllAdvisorsRequestSuccess: (state, action) => {
        state.isGetAllAdvisorsRequestLoading = false;
        state.advisorsGetAllRequestSuccessData = action.payload;
        state.advisorsGetAllRequestFailedData = {};
    },
    getAllAdvisorsRequestFailed: (state, action) => {
        state.isGetAllAdvisorsRequestLoading = false;
        state.advisorsGetAllRequestSuccessData = {};
        state.advisorsGetAllRequestFailedData = action.payload;
    },

    //find advisor detail 
    getFindAdvisorDetailByIdRequest: (state) => {
        state.isGetFindAdvisorDetailByIdRequestLoading = true
        state.selectedAdvisorDetailSuccessData = {}
        state.selectedAdvisorDetailFailedData = {}
    },
    getFindAdvisorDetailByIdRequestSuccess: (state, action) => {
        state.isGetFindAdvisorDetailByIdRequestLoading = false
        state.selectedAdvisorDetailSuccessData = action.payload
        state.selectedAdvisorDetailFailedData = {}
    },
    getFindAdvisorDetailByIdRequestFailed: (state, action) => {
        state.isGetFindAdvisorDetailByIdRequestLoading = false
        state.selectedAdvisorDetailSuccessData = {}
        state.selectedAdvisorDetailFailedData = action.payload
    },

    // allow or block portal access
    patchAllowOrBlockAccessToAdvisorPortalRequest: (state) => {
        state.isPatchAllowOrBlockAccessToAdvisorPortalRequestLoading = true;
        state.allowOrBlockAccessToAdvisorPortalSuccessData = {};
        state.allowOrBlockAccessToAdvisorPortalFailedData = {};
    },
    patchAllowOrBlockAccessToAdvisorPortalRequestSuccess: (state, action) => {
        state.isPatchAllowOrBlockAccessToAdvisorPortalRequestLoading = false;
        state.allowOrBlockAccessToAdvisorPortalSuccessData = action?.payload;
        state.allowOrBlockAccessToAdvisorPortalFailedData = {};
    },
    patchAllowOrBlockAccessToAdvisorPortalRequestFailed: (state, action) => {
        state.isPatchAllowOrBlockAccessToAdvisorPortalRequestLoading = false;
        state.allowOrBlockAccessToAdvisorPortalSuccessData = {};
        state.allowOrBlockAccessToAdvisorPortalFailedData = action?.payload;
    },

});
