import React from 'react';
import {
  Grid,
  Typography,
  Avatar,
  Paper,
  Box,
  Tooltip,
  Divider,
  SvgIcon,
  IconButton,
} from '@mui/material';

import { useCommonStyles } from 'styles/styles';

import { ReactComponent as BlockActionIcon } from 'assets/images/icons/block-action.svg';
import { ReactComponent as CheckActionIcon } from 'assets/images/icons/check-action.svg';
import { ReactComponent as AdvisorIcon } from 'assets/images/users/advisor-avatar-round.svg';

const boxStyle = {
  cursor: 'pointer',
};

const AdvisorProfileCard = (props) => {
  const {
    openAddFileNoteModal,
    openTaskModal,
    openSendEmailModal,
    openClientArchiveModal,
    openAdvisorProfile,
    profileData,
  } = props;
  const { classes: commonClasses } = useCommonStyles();

  //Handle open client profile on list item click
  const handleOnProfileClick = () => {
    let clientData = {};
    openAdvisorProfile && openAdvisorProfile(clientData);
  };

  /**
   * Format statistic
   * @param {*} value
   * @returns
   */
  const formatStatistic = (value) => {
    return (value.toString && value.toString().padStart(2, '0')) || '00';
  };
  return (
    <Paper
      sx={(theme) => ({
        cursor: 'pointer',
        boxShadow: `inset ${
          !profileData?.paymentStatus ? theme.palette.red?.[800] : theme.palette.green?.[200]
        } 0px 0.5rem 0px 0px`,
        border:
          '2px solid ' +
          (!profileData?.paymentStatus ? theme.palette.red?.[800] : theme.palette.green?.[200]),
        position: 'relative',
        height: '100%',
      })}
      elevation={1}
    >
      <Box sx={{ position: 'absolute', right: '0.75rem', top: '1rem' }}>
        <IconButton size="small">
          {!profileData?.paymentStatus ? (
            <Box>{<BlockActionIcon />}</Box>
          ) : (
            <Box>{<CheckActionIcon />}</Box>
          )}
        </IconButton>
      </Box>
      <Grid container spacing={2} my={'0.005rem'}>
        <Grid
          container
          item
          xs={12}
          justifyContent={'center'}
          onClick={handleOnProfileClick}
          mx={3}
          my={1}
        >
          <Avatar
            variant="circular"
            sx={{
              color: '#fff',
              m: 1,
              width: '5rem',
              height: '5rem',
            }}
            component={AdvisorIcon}
          ></Avatar>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={'center'}
          onClick={handleOnProfileClick}
          mx={3}
          my={1}
        >
          <Typography className={commonClasses.profileName1}>{profileData?.advisorName}</Typography>
        </Grid>
        <Grid item xs={12} mx={3} my={1} onClick={handleOnProfileClick}>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs="auto">
              <Typography
                sx={(theme) => ({
                  color: theme.palette.blue?.[300],
                })}
              >
                Total Client
              </Typography>
            </Grid>
            <Grid item xs>
              <Grid container justifyContent={'flex-end'}>
                <Grid item xs="auto">
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.blue?.[300],
                      fontWeight: theme.typography.fontWeightMedium,
                    })}
                  >
                    {formatStatistic(profileData?.noOfClients || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1} onClick={handleOnProfileClick}>
            <Grid item xs="auto">
              <Typography
                sx={(theme) => ({
                  color: theme.palette.blue?.[750],
                })}
              >
                Total Lead
              </Typography>
            </Grid>
            <Grid item xs>
              <Grid container justifyContent={'flex-end'}>
                <Grid item xs="auto">
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.grey?.[800],
                      fontWeight: theme.typography.fontWeightMedium,
                    })}
                  >
                    {formatStatistic(profileData?.noOfLeads || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} onClick={handleOnProfileClick}>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item xs={12} mx={3} mt={1} mb={3} onClick={handleOnProfileClick}>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Typography
                sx={(theme) => ({
                  color: theme.palette.grey?.[800],
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                Payment Status
              </Typography>
            </Grid>
            <Grid item xs>
              <Grid container justifyContent="flex-end">
                <Typography
                  sx={(theme) => ({
                    color: !profileData?.paymentStatus
                      ? theme.palette.red?.[800]
                      : theme.palette.green?.[200],
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                >
                  {profileData?.paymentStatus ? 'Paid' : 'Not Paid'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AdvisorProfileCard;
