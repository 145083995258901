import { createReducer } from '@reduxjs/toolkit';

// error, warning, info, success

export const initialState = {
    snackText: "",
    snackVariant: ""
};

export const notificationReducer = createReducer(initialState, {
    HANDLE_NOTIFICATION: (state, action) => {
        state.snackText = action.payload.snackText;
        state.snackVariant = action.payload.snackVariant;
    },
});
