import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //composite data request
  compositeRequestLoading: false,
  compositeData: {},
  compositeFailed: {},
};

export const compositeDataReducer = createReducer(initialState, {
  //get composites
  getCompositeRequestRequest: (state) => {
    state.compositeRequestLoading = true;
    state.compositeData = {};
    state.compositeFailed = {};
  },
  getCompositeRequestSuccess: (state, action) => {
    state.compositeRequestLoading = false;
    state.compositeData = action.payload;
    state.compositeFailed = {};
  },
  getCompositeRequestFailed: (state, action) => {
    state.compositeRequestLoading = false;
    state.compositeData = {};
    state.compositeFailed = action.payload;
  },

  //reset composite data
  resetCompositeData: (state, action) => {
    state = initialState;
  },
});
