import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme) => ({
  mainContainerWrapper: {
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    border: `1px solid #C7D2E9`,
    borderRadius: 5,
    padding: '5px 5px 1px 15px',
    backgroundColor: '#ffffff',
    // paddingLeft: "1rem",
    // margin: "0px 0px 0px 9px",
  },
  mainTitle: {
    color: '#535353',
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  profileName1: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'capitalize',
  },
  profileName2: {
    color: '#2196F3',
    fontWeight: '700',
    fontSize: '1rem',
  },
  labelBlue: {
    color: '#003761',
    fontWeight: '500',
    fontSize: 14,
  },
  labelGreen: {
    color: '#0D7223',
    fontWeight: '500',
    fontSize: 14,
  },
  labelOrange: {
    color: '#FF5A3B',
    fontWeight: '500',
    fontSize: 14,
  },
  labelRed: {
    color: '#F40505',
    fontWeight: '500',
    fontSize: 14,
  },
  blueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#273375',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    border: '2px solid #273375',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  greenChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#06BF2E',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    border: '2px solid #06BF2E',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  yellowChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#FFC839',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    border: '2px solid #FFC839',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  lightBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#1EB0B0',
    lineHeight: 1.5,
    backgroundColor: '#fff',
    border: '2px solid #1EB0B0',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  containerBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#fff',
    lineHeight: 1.5,
    backgroundColor: '#273375',
    border: '2px solid #273375',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  containerLightBlueChip: {
    padding: '2px 10px',
    fontSize: 13,
    color: '#fff',
    lineHeight: 1.5,
    backgroundColor: '#1EB0B0',
    border: '2px solid #1EB0B0',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    width: 'max-content',
    fontWeight: '500',
  },
  mainDivider: {
    width: '100%',
    height: '1px',
    background: '#2EBA88',
    borderStyle: ' none',
  },
  formTitle: {
    color: '#2EBA88',
    fontWeight: 700,
    fontSize: '1.2rem',
  },
  formSubContainer: {
    padding: 20,
  },
  formLabel: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  requiredAsterisk: {
    position: 'absolute',
    right: '0.4rem',
    top: '1.8rem',
    color: theme.palette.error.main,
    zIndex: 1,
  },
  errorMessage: {
    backgroundColor: theme.palette.primary.tooLiter,
    color: theme.palette.error.main,
  },
  datePickerClass: {
    width: '100% !important',
    backgroundColor: 'red',
  },
  saveBtn: {
    // backgroundColor: theme.palette.main.primaryLight,
    // color: theme.palette.common.white,
    // height: theme.buttons.height.medium,
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    '&:hover': {
      // backgroundColor: theme.palette.main.dark,
    },
  },
  cancelBtn: {
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
  },
  clearBtn: {
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    color: theme.palette.grey?.[400],
    borderColor: theme.palette.grey?.[400],
    '&:hover': {
      color: theme.palette.grey?.[400],
      borderColor: theme.palette.grey?.[400],
    },
  },
  updateBtn: {
    textTransform: 'capitalize',
    outline: 'none',
    textDecoration: 'none',
    backgroundColor: theme.palette.blue?.[700],
    borderColor: theme.palette.blue?.[700],
    '&:hover': {
      backgroundColor: theme.palette.blue?.[700],
      borderColor: theme.palette.blue?.[700],
    },
  },
  cameraIcon: {
    height: 22,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: -12,
    marginLeft: 24,
  },
  addIcon: {
    fontSize: '1.5rem',
    // color: theme.palette.primary.dark,
    borderRadius: '0.5rem',
    marginLeft: '1rem',
    marginTop: '0.5rem',
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: '1.5rem',
    // color: theme.palette.reds.dark,
    borderRadius: '0.5rem',
    marginLeft: '1rem',
    marginTop: '0.5rem',
    cursor: 'pointer',
  },
  modalTitleBox: {
    // background: theme.palette.primary.main,
    background: '#fff',
    color: '#fff',
  },
  modalTitle: {
    color: '#215273',
    fontWeight: '700',
    fontSize: '25px',
  },
  modalActionBox: {
    marginTop: 30,
  },
  modalContent: {
    backgroundColor: '#F5FFFC',
    border: '1px solid #2EBA88',
    width: '100%',
  },
  confirmationModalHeader: {
    // background: theme.palette.brandPrimary.dark,
    fontSize: '20px',
    color: '#575555',
    // borderBottom: "2px solid #000",
    '&:hover': {
      color: '#575555',
    },
  },
  dialogCloseBtn: {
    float: 'right',
    marginTop: '0.1rem',
    cursor: 'pointer',
  },
  conformationContentText: {
    color: '#000',
    fontSize: 16,
  },
  outlineBtn: {
    color: '#2659B6',
    border: '1px solid #2659B6',
    outline: 'none',
    height: '2.25rem',
    // marginRight: "0.9rem",
    textTransform: 'none',
    '&:hover': {
      color: '#2659B6',
    },
  },
  mainButton: {
    backgroundColor: '#2659B6',
    color: '#fff',
    outline: 'none',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2659B6',
      color: '#fff',
    },
  },
  confirmationModalContent: {
    backgroundColor: '#F5FFFC',
    border: '1px solid #2EBA88',
    width: '100%',
    padding: 20,
  },
  greyText: {
    fontSize: '15px',
    color: '#535353',
  },
  greyTextBolt: {
    fontSize: '15px',
    color: '#535353',
    fontWeight: 500,
  },
  mainContainer: {
    backgroundColor: '#F5FFFC',
    border: '1px solid #2EBA88',
    width: '100%',
  },
  mainContainer_Wrap: {
    backgroundColor: '#F5FFFC',
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    borderRadius: 5,
    padding: 2,
  },
  //combined fields
  combinedFieldLeftSelect: {
    '& fieldset': {
      borderRadius: '0.75rem 0 0 0.75rem',
    },
  },
  combinedFieldRightInput: {
    '& fieldset': {
      borderRadius: '0 0.75rem 0.75rem 0',
    },
  },
  activeButton: {
    backgroundColor: '#108C60',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#108C60',
      color: '#fff',
    },
  },
  inactiveButton: {
    backgroundColor: '#6DFFCB',
    color: '#108C60',
    '&:hover': {
      backgroundColor: '#6DFFCB',
      color: '#108C60',
    },
  },
  listContainer: {
    width: '100%',
    padding: 5,
    margin: '5px 0px',
    cursor: 'pointer',
  },
  cardItemTextType_1: {
    color: '#000',
  },
  cardItemTextType_2: {
    color: '#000',
    fontWeight: 500,
  },
  formText_1: {
    color: '#000',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  formText_2: {
    color: '#000',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  redBtnSmall: {
    backgroundColor: '#FF7186',
    '&:hover': {
      backgroundColor: '#FF7186',
    },
  },
}));
