import React from 'react';
import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import NavigationCard from 'ui-component/dashboard/NavigationCard';
import MainCard from 'ui-component/cards/MainCard';
import FormTopContainer from 'ui-component/dashboard/FormTopContainer';

import * as financialChartsAnimationData from 'assets/animation/financial-charts.json';
import * as mobileAppAnimationData from 'assets/animation/mobile-app.json';

const AdvisorSettings = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid container item xs={12}>
        <MainCard>
          <FormTopContainer title={'Advisor Setting'} />
        </MainCard>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <NavigationCard
                      title={'Advisor Portal Setting'}
                      path={'settings/advisorPortalSettings'}
                      img={mobileAppAnimationData}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <NavigationCard
                      title={'Advisors'}
                      path={'settings/advisorsSettings'}
                      img={financialChartsAnimationData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorSettings;
