import React from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Grid,
  Dialog,
  Stack,
  DialogContent,
  DialogTitle,
  Slide,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";

import { gridSpacing } from "store/constant";
import { useCommonStyles } from "styles/styles";
import SyncFusionEditor from "common/SyncFusionEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const options = ["Option 1", "Option 2"];

const Compose = () => {
  const { classes: commonClasses } = useCommonStyles();

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  const [isShowCc, setIsShowCc] = React.useState(false);

  const [bodyData, setBodyData] = React.useState('TEST');

  const handleIsShowCc = () => {
    setIsShowCc(!isShowCc);
  };

  const handleEditorChange = (data, a, b) => {
    console.log('saving data', data, a, b)
  }


  return (
    <Grid
      container
      spacing={gridSpacing}
      style={{ marginTop: "1rem", width: "100%" }}
    >
      <Grid container item xs={12}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={options}
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="To" />}
        />
      </Grid>
      {!isShowCc && (
        <Grid container item xs={12}>
          <Typography
            onClick={handleIsShowCc}
            color={"primary"}
            sx={{ cursor: "pointer", fontWeight: 500 }}
            variant={"3"}
          >
            Cc/Bcc
          </Typography>{" "}
        </Grid>
      )}
      {isShowCc && (
        <Grid container item xs={12}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="CC" />}
          />
        </Grid>
      )}
      {isShowCc && (
        <Grid container item xs={12}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="BCC" />}
          />
        </Grid>
      )}

      <Grid container item xs={12}>
        <TextField variant="outlined" label={"Subject"} />
      </Grid>
      
      <Grid container item xs={12}>
        <SyncFusionEditor 
            label="Message"
            editorId="email-compose-editor"
            onChange={handleEditorChange}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Stack
          direction={"row"}
          spacing={2}
          className={commonClasses.modalActionBox}
        >
          {/* <Button
        variant="outlined"
        className={commonClasses.cancelBtn}
        id={"cancel-btn"}
        onClick={handleCloseDialog}
      >
        Cancel
      </Button> */}

          <Button
            className={commonClasses.saveBtn}
            id={"save-btn"}
            type="submit"
            variant={"contained"}
          >
            Save
            {/* {loading || updateLoading ? (
                           <CircularProgress style={{ color: "#fff" }} size={25} />
                     ) : (
                    "Create"
                    )} */}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Compose;
