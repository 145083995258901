import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Grid, Stack, TextField, Checkbox } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';

import { ToolbarType } from '@syncfusion/ej2-react-richtexteditor';

import ComposeSkeleton from 'ui-component/skeletons/email/ComposeSkeleton';
import SyncFusionEditor from 'common/SyncFusionEditor';
import { handleContentReplacement } from 'const/documentTags';

import { requestBulkEmail } from 'redux/Actions/emailAction';
import { handleNotification } from 'redux/Actions/handleNotificationAction';
import { requestGetAllAdvisors } from 'redux/Actions/advisorProfileAction';
import { gridSpacing } from 'store/constant';

import { useCommonStyles } from 'styles/styles';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SendRounded } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Compose = () => {
  const { classes: commonClasses } = useCommonStyles();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  /**
   * Uses react-router-dom location
   */
  const location = useLocation();
  // email store
  const emailPostsReducer = useSelector((state) => state?.emailPostsReducer);

  const isEmailPosting = emailPostsReducer?.isBulkEmailPosting;
  //email template store
  const emailTemplateReducer = useSelector((state) => state?.emailTemplateReducer);
  const isGetAllEmailTemplatesLoading = emailTemplateReducer?.isGetAllEmailTemplatesLoading;
  const emailTemplatesData = emailTemplateReducer?.emailTemplatesData;
  //login user
  const loginUserData = useSelector((state) => state.authenticationReducer?.loginData?.user);
  // client list data
  const clientListData = useSelector((state) => state.clientReducer?.clientListData);

  //_advisor profile store
  const advisorProfileSettingsReducer = useSelector((state) => state.advisorProfileSettingsReducer);
  const isGetAdvisorProfileSettingsLoading =
    advisorProfileSettingsReducer?.isGetAdvisorProfileSettingsLoading;

  //_rte component ref
  const documentTextEditorRef = useRef();
  //_editor tag btn ref
  const insertContentBtnRTef = useRef();
  //_email signature ref
  const emailSignatureRef = useRef();
  //_email template ref
  const selectedEmailTemplateRef = useRef();
  //states
  const [emailRecipientOptions, setEmailRecipientOptions] = useState([]);

  const [senderValue, setSenderValue] = useState([]);

  const [senderInputValue, setSenderInputValue] = useState([]);

  const [subjectValue, setSubjectValue] = useState('');

  const [bodyData, setBodyData] = useState('');

  const [formErrorData, setFormErrorData] = useState({
    to: {
      error: false,
      helperText: ' ',
    },
    subject: {
      error: false,
      helperText: ' ',
    },
    message: {
      error: false,
      helperText: ' ',
    },
  });

  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
  /**
   * Add Email Signature
   */
  const addSignatureCallback = () => {
    const emailSignature = emailSignatureRef.current;
    if (emailSignatureRef.current) {
      documentTextEditorRef?.current?.executeCommand &&
        documentTextEditorRef.current.executeCommand('insertHTML', emailSignature + ' &nbsp;');
      documentTextEditorRef?.current?.updateValue && documentTextEditorRef.current.updateValue(); //to invoke the painting on editor(to finalize the current addition)
      const currentValue = documentTextEditorRef.current?.value;
      currentValue && setBodyData(currentValue);
    }
  };

  const editorToolbarSettings = {
    items: [
      'Undo',
      'Redo',
      '|',
      {
        tooltipText: 'Email Signature',
        undo: true,
        click: function (event) {
          event?.preventDefault && event.preventDefault();
          const thisContext = this;
          const currentTarget = thisContext?.parent?.currentTarget;
          if (currentTarget) {
            insertContentBtnRTef.current = currentTarget;
            addSignatureCallback();
          }
        },
        template:
          '<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><span style="font-size:0.825rem; color: darkslategrey; font-weight: 500;">Signature</span></div></button>',
      },
      '|',
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      'FileManager',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      //
      // '|',
      // 'Video',
    ],
    type: ToolbarType.Expand,
    // pasteCleanupSettings: {
    //     deniedTags: ['iframe', 'embed'],
    //     keepFormat: true,
    //     plainText: true,
    //     html: true,
    //     prompt: true
    // }
  };

  /**
   * Email message changed
   * @param {import("@syncfusion/ej2-react-richtexteditor").ChangeEventArgs} data
   */
  const handleEmailMessageChange = (data) => {
    let [editorContent, changeEventName, isEditorInteracted] = [
      data?.value,
      data?.name,
      data?.isInteracted,
    ];

    setBodyData(editorContent);
    handleFieldValidation('message', editorContent?.length);
  };
  /**
   * Handle form reset
   */
  const handleFormReset = () => {
    setSenderValue([]);
    setSenderInputValue([]);

    setSubjectValue('');
    setBodyData('');
  };
  /**
   * Handle submission form validation
   * @returns validation
   */
  const handleValidateEmailPostForm = () => {
    let [isFormError, errorMessage] = [false, ''];

    if (senderValue.length === 0 ? true : false) {
      isFormError = true;
      errorMessage = 'Please Provide Sender Email !';
    }
    if (!subjectValue) {
      isFormError = true;
      errorMessage = 'Please Provide Email Subject !';
    }
    if (!bodyData) {
      isFormError = true;
      errorMessage = 'Please Provide Email Body !';
    }

    return {
      isFormError,
      errorMessage,
    };
  };
  /**
   * Handle Send mail
   * @param {*} e
   */
  const handleSendEmail = (e) => {
    const { isFormError, errorMessage } = handleValidateEmailPostForm();
    if (!isFormError) {
      const emailPostingRequestData = {
        subject: subjectValue,
        toAddress: senderValue.map((data) => data.emailAddress),
        senderEmailAddress: 'support@souffle.com.au',
        body: bodyData,
        emailType: 'bulkMessage',
      };
      const handleEmailPostingRequestSuccessCallback = () => {
        handleFormReset();
      };
      dispatch(requestBulkEmail(emailPostingRequestData, handleEmailPostingRequestSuccessCallback));
    } else {
      dispatch(handleNotification(errorMessage, 'error'));
    }
  };

  /**
   * Get Client List for available sender list
   */
  const handleGetClientList = () => {
    const handleGetAllAdvisorRequestSuccessCallback = (response) => {
      const clientOptions =
        response?.map &&
        response.map((singleClient) => ({
          clientId: singleClient?._id,
          clientName:
            (singleClient?.lastName && singleClient?.firstName + ' ' + singleClient.lastName) ||
            singleClient.firstName ||
            singleClient?.firstName,
          emailAddress: singleClient?.emailAddress,

          id: singleClient?._id,
          value: singleClient?.emailAddress,
          name:
            (singleClient?.lastName && singleClient?.firstName + ' ' + singleClient.lastName) ||
            singleClient.firstName ||
            singleClient?.firstName,
        }));
      setEmailRecipientOptions((prev) => [...clientOptions]);
      if (location?.state?.assignee._id) {
        const assigneeDetail = clientOptions.find(
          (assignee) => assignee?.id === location?.state?.assignee?._id
        );
        setSenderValue(assigneeDetail);
      }
    };
    dispatch(requestGetAllAdvisors(handleGetAllAdvisorRequestSuccessCallback));
  };
  /**
   * Handle field validation
   * @param {*} fieldType
   * @param {*} value
   */
  const handleFieldValidation = (fieldType, value) => {
    setFormErrorData((prev) => ({
      ...prev,
      [fieldType]: {
        error: !value,
        helperText: (!value && 'Required!') || ' ',
      },
    }));
  };
  /**
   * Get advisor profile
   */
  const handleGetAdvisorProfile = () => {
    // dispatch(requestGetAdvisorProfileSetting());
  };
  /**
   * On Editor created callback
   * @param {*} args
   * @param {*} editor
   */
  const handleEditorCreationCallback = (args, editor) => {
    documentTextEditorRef.current = editor;
  };
  /**
   * Effect on email template data
   */
  useEffect(() => {
    if (Array.isArray(emailTemplatesData)) {
      const tempOptionData = emailTemplatesData.map((templateDetails) => ({
        ...templateDetails,
        value: templateDetails?._id,
        label: templateDetails?.name,
      }));
      setEmailTemplateOptions(tempOptionData);
    }
  }, [isGetAllEmailTemplatesLoading, emailTemplatesData?.length]);
  /**
   * Effect on selected email template
   */
  useEffect(() => {
    if (selectedEmailTemplate) {
      selectedEmailTemplateRef.current = selectedEmailTemplate;
      let emailTemplateOption = emailTemplateOptions.find(
        (option) => option?.value === selectedEmailTemplate
      );
      if (emailTemplateOption) {
        setSubjectValue(emailTemplateOption?.subject);
        const templateContent = emailTemplateOption?.body;
        const selectedClientData =
          Array.isArray(clientListData) &&
          clientListData.find((clientDetail) => clientDetail?._id === senderValue?.clientId);
        const availableData = {
          clientData: selectedClientData,
          advisorData: loginUserData,
        };
        if (availableData?.clientData?._id && availableData?.advisorData?._id) {
          const updatedMessage = handleContentReplacement(templateContent, availableData);
          setBodyData(updatedMessage);
        } else {
          setBodyData(emailTemplateOption?.body);
        }
      }
    } else if (selectedEmailTemplateRef.current) {
      setSubjectValue('');
      setBodyData('');
      selectedEmailTemplateRef.current = undefined;
    }
  }, [selectedEmailTemplate, senderValue, loginUserData]);
  /**
   * Effect on email signature
   */
  useEffect(() => {
    if (advisorProfileSettingsReducer?.advisorProfileSettingsSuccessData?.emailSignature) {
      emailSignatureRef.current =
        advisorProfileSettingsReducer?.advisorProfileSettingsSuccessData?.emailSignature;
    }
  }, [isGetAdvisorProfileSettingsLoading]);

  /**
   * Effect on Mount
   */
  useEffect(() => {
    handleGetClientList();

    handleGetAdvisorProfile();
  }, []);

  return isEmailPosting ? (
    <ComposeSkeleton />
  ) : (
    <Grid container spacing={gridSpacing} style={{ width: '100%', padding: '1rem 0 1rem 1rem' }}>
      <Grid container item xs={12}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id={'client-auto-complete'}
          value={senderValue}
          onChange={(event, newValue) => {
            if (newValue.find((option) => option.all))
              return setSenderValue(
                senderValue.length === emailRecipientOptions.length ? [] : emailRecipientOptions
              );

            setSenderValue(newValue);
          }}
          inputValue={senderInputValue}
          onInputChange={(event, newInputValue) => {
            setSenderInputValue(newInputValue);
          }}
          onBlur={(event) => {
            handleFieldValidation('to', senderValue.length === 0 ? false : true);
          }}
          options={emailRecipientOptions}
          sx={{
            width: '100%',
            '& .MuiAutocomplete-input': {
              padding: '0.25rem !important',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="To"
              error={!!formErrorData.to?.error}
              helperText={formErrorData.to?.helperText}
            />
          )}
          getOptionLabel={(option) => option['name'] || ''}
          isOptionEqualToValue={(option, value) => option?.['id'] === value?.['id']}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  option.all ? !!(senderValue.length === emailRecipientOptions.length) : selected
                }
              />
              {option.name}
              <br />
              {option.emailAddress}
            </li>
          )}
          filterOptions={(options, params) => {
            // <<<--- inject the Select All option
            const filter = createFilterOptions();
            const filtered = filter(options, params);
            return [{ name: 'Select All...', all: true }, ...filtered];
          }}
        />
      </Grid>

      <Grid container item xs={12}>
        <TextField
          id="mail-subject-field"
          variant="outlined"
          label={'Subject'}
          value={subjectValue}
          onChange={(event) => setSubjectValue(event?.target?.value || '')}
          error={!!formErrorData?.subject?.error}
          helperText={formErrorData?.subject?.helperText}
          onBlur={(event) => {
            handleFieldValidation('subject', subjectValue?.length);
          }}
          fullWidth
        />
      </Grid>

      <Grid container item xs={12}>
        <SyncFusionEditor
          label="Message"
          editorId="email-body-compose-editor"
          onEditorChange={handleEmailMessageChange}
          editorValue={bodyData}
          error={!!formErrorData?.message?.error && formErrorData?.message?.helperText}
          toolbarSettings={editorToolbarSettings}
          handleEditorCreationCallback={handleEditorCreationCallback}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Stack direction={'row'} spacing={2} className={commonClasses.modalActionBox}>
          <LoadingButton
            className={commonClasses?.['saveBtn']}
            id={'save-btn'}
            endIcon={<SendRounded />}
            loading={isEmailPosting}
            loadingPosition="end"
            variant="contained"
            onClick={handleSendEmail}
          >
            Send Email
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Compose;
