import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { registerLicense } from '@syncfusion/ej2-base';
import App from 'App';
import Store from "./redux/Store"

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import ErrorBoundary from 'ui-component/ErrorBoundary';
import PageError from 'ui-component/PageError';

//redux persistor
let persistor = persistStore(Store);

// Registering Syncfusion license key
registerLicense(process.env['REACT_APP_SYNCFUSION_KEY'] || '');

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={Store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter basename={config.basename}>
                <ErrorBoundary fallback={<PageError />}>
                    {/* <BrowserRouter basename={config.basename}> */}
                    <App />
                </ErrorBoundary>

            </BrowserRouter>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
