import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import OrganizationDetails from 'views/organizations/OrganizationDetails/OrganizationDetails';
import NotificationManagement from 'views/notifications/notificationManagement/NotificationManagement';
import AdvisorSettings from 'views/settings/AdvisorSettings';
import AdvisorPortalSettings from 'views/settings/advisorPortalSetting/AdvisorPortalSettings';
import AdvisorsSettings from 'views/settings/advisorsSetting/AdvisorsSettings';
import AdvisorDetails from 'views/settings/advisorsSetting/advisorDetails/AdvisorDetail';
import BulkEmail from 'views/email/BulkEmail';

const NewDashboard = Loadable(lazy(() => import('views/dashboard/AdminApp')));

const WordTemplates = Loadable(lazy(() => import('views/wordTemplates/WordTemplates')));
const Superannuation = Loadable(lazy(() => import('views/superannuation/Superannuation')));
const Subscriptions = Loadable(lazy(() => import('views/subscriptions/Subscriptions')));
const PaymentHistory = Loadable(lazy(() => import('views/paymentHistory/PaymentHistory')));
const Notification = Loadable(lazy(() => import('views/notifications/AdminNotification')));
const OmniumUsers = Loadable(lazy(() => import('views/omniumUsers/OmniumUsers')));

const Organizations = Loadable(lazy(() => import('views/organizations/Organizations')));
const PromoCodes = Loadable(lazy(() => import('views/promoCodes/PromoCodes')));

const TaxSettings = Loadable(
  lazy(() => import('views/settings/advisorPortalSetting/taxSetting/TaxSettings'))
);

const CreateIndividualTaxSetting = Loadable(
  lazy(() =>
    import(
      'views/settings/advisorPortalSetting/taxSetting/individualTax/CreateIndividualTaxSetting'
    )
  )
);

const EmailTemplate = Loadable(lazy(() => import('views/emailTemplate/EmailTemplate')));

const CreateEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/CreateEmailTemplate')));

const MainRoutes = {
  path: '/main',
  element: <MainLayout />,
  children: [
    {
      path: 'newDashboard',
      element: <NewDashboard />,
    },
    {
      path: 'organizations',
      element: <Organizations />,
    },
    {
      path: 'organizations/organizationDetails',
      element: <OrganizationDetails />,
    },
    {
      path: 'promoCodes',
      element: <PromoCodes />,
    },
    {
      path: 'wordTemplates',
      element: <WordTemplates />,
    },
    {
      path: 'superannuation',
      element: <Superannuation />,
    },
    {
      path: 'subscriptions',
      element: <Subscriptions />,
    },
    {
      path: 'paymentHistory',
      element: <PaymentHistory />,
    },
    {
      path: 'notifications',
      element: <Notification />,
    },
    {
      path: 'notifications/send',
      element: <NotificationManagement />,
    },
    {
      path: 'omniumUsers',
      element: <OmniumUsers />,
    },

    {
      path: 'settings',
      element: <AdvisorSettings />,
    },
    {
      path: 'settings/advisorPortalSettings',
      element: <AdvisorPortalSettings />,
    },
    {
      path: 'settings/taxSettings',
      element: <TaxSettings />,
    },
    {
      path: 'settings/create-individual-tax',
      element: <CreateIndividualTaxSetting />,
    },
    {
      path: 'settings/advisorsSettings',
      element: <AdvisorsSettings />,
    },
    {
      path: 'settings/advisorsSettings/advisorDetails',
      element: <AdvisorDetails />,
    },

    {
      path: 'email',
      element: <BulkEmail />,
    },
    {
      path: 'email-template',
      element: <EmailTemplate />,
    },
    {
      path: 'create-email-template',
      element: <CreateEmailTemplate />,
    },
  ],
};

export default MainRoutes;
