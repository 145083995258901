import React from "react";
import {
  Grid,
  Typography,
  Avatar,
  Paper,
  Stack,
  Box,
  Tooltip,
} from "@mui/material";

import { deleteSvg } from "assets/svg/svgImg";

import { useCommonStyles } from "styles/styles";

const boxStyle = {
  cursor: "pointer",
};

const EmilList = (props) => {
  const { deleteVideo } = props;

  const { classes: commonClasses } = useCommonStyles();
  return (
    <Paper
      sx={{
        width: "100%",
        padding: 1,
        margin: "10px 0px",
      }}
      elevation={1}
    >
      <Grid container item xs={12}>
        <Grid container item xs={3} alignItems={"center"}>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Avatar />
            <Typography className={commonClasses.greyTextBolt}>
              Darryl Pike
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={6} alignItems={"center"}>
          <Typography className={commonClasses.greyText}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography>
        </Grid>

        <Grid container item xs={2} alignItems={"center"}>
          <Typography className={commonClasses.greyText}>
            2022/10/02 6.23
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={1}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip title="Delete">
              <Box sx={boxStyle} onClick={deleteVideo}>
                {deleteSvg}
              </Box>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmilList;
