import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  //bulk email send
  isBulkEmailPosting: false,
  bulkEmailPostSuccessData: {},
  bulkEmailPostFailedData: {},
};

/**
 * Client Invitation reducer
 */
export const emailPostsReducer = createReducer(initialState, {
  // bulk email posting request reducer
  postBulkEmailRequest: (state) => {
    state.isBulkEmailPosting = true;
    state.bulkEmailPostSuccessData = {};
    state.bulkEmailPostFailedData = {};
  },
  postBulkEmailRequestSuccess: (state, action) => {
    state.isBulkEmailPosting = false;
    state.bulkEmailPostSuccessData = action.payload;
    state.bulkEmailPostFailedData = {};
  },
  postBulkEmailRequestFailed: (state, action) => {
    state.isBulkEmailPosting = false;
    state.bulkEmailPostSuccessData = {};
    state.bulkEmailPostFailedData = action.payload;
  },
});
