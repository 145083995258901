import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationControlled = (props) => {
  const { pageNo, pageSize, totalElements, handlePageChange } = props;

  return (
    <Stack justifyContent="flex-end">
      <Pagination
        count={Math.ceil(+totalElements / +pageSize)}
        page={pageNo}
        onChange={(event, page) =>
          typeof handlePageChange === 'function' && handlePageChange(event, page)
        }
      />
    </Stack>
  );
};

export default PaginationControlled;
