import Store from 'redux/Store';
import { API_URL } from 'shared/API_URLS';
import http_request from 'utils/http-request';
import { handleNotification } from './handleNotificationAction';

/**
 * Get all account owners
 * @param {*} handleAdvisorsListRequestSuccessCallback 
 * @param {*} handleAdvisorsListRequestFailedCallback 
 * @returns 
 */
export const requestGetAllAdvisors = (handleAdvisorsListRequestSuccessCallback = null, handleAdvisorsListRequestFailedCallback = null) => async (dispatch, getState) => {
    dispatch({ type: 'getAllAdvisorsRequest' });

    const requestData = {
        url: API_URL.advisorProfile.GET_ALL_ADVISORS,
        method: 'GET',
    };
    const successCallback = (response) => {
        dispatch({ type: 'getAllAdvisorsRequestSuccess', payload: response.data });
        handleAdvisorsListRequestSuccessCallback && handleAdvisorsListRequestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
        dispatch({ type: 'getAllAdvisorsRequestFailed', payload: error });
        handleAdvisorsListRequestFailedCallback && handleAdvisorsListRequestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
};

/**
 * Find account owner detail by id
 * @param {*} handleFindAdvisorDetailsRequestSuccessCallback 
 * @param {*} handleFindAdvisorDetailsRequestFailedCallback 
 * @returns 
 */
export const requestFindAdvisorDetail = (requestDetails, handleFindAdvisorDetailsRequestSuccessCallback = null, handleFindAdvisorDetailsRequestFailedCallback = null) => async (dispatch, getState) => {
    dispatch({ type: 'getFindAdvisorDetailByIdRequest' });

    const {
        accountOwnerId
    } = requestDetails;

    const requestData = {
        url: API_URL.advisorProfile.GET_FIND_ADVISOR_DETAIL_BY_ID.replace(
            '{accountOwnerId}',
            accountOwnerId
        ),
        method: 'GET',
    };
    const successCallback = (response) => {
        dispatch({ type: 'getFindAdvisorDetailByIdRequestSuccess', payload: response.data });
        handleFindAdvisorDetailsRequestSuccessCallback && handleFindAdvisorDetailsRequestSuccessCallback(response?.data);
    };
    const errorCallback = (error) => {
        dispatch({ type: 'getFindAdvisorDetailByIdRequestFailed', payload: error });
        handleFindAdvisorDetailsRequestFailedCallback && handleFindAdvisorDetailsRequestFailedCallback(error);
    };
    http_request(requestData, successCallback, errorCallback);
};

/**
 * Request access change for Advisor Portal
 * @param {*} requestDetail 
 * @param {*} handleRequestSuccessCallback 
 * @param {*} handleRequestFailedCallback 
 * @returns 
 */
export const requestBlockOrAllowAdvisorPortalAccess = (requestDetail, handleRequestSuccessCallback = null, handleRequestFailedCallback = null) => async (dispatch, getState) => {
    dispatch({ type: 'patchAllowOrBlockAccessToAdvisorPortalRequest' });

    const {
        requestBody,
        advisorId
    } = requestDetail;

    const requestData = {
        url: API_URL.advisorProfile.PATCH_BLOCK_OR_ALLOW_ADVISOR_PORTAL_ACCESS.replace(
            '{accountOwnerId}',
            advisorId
        ),
        method: 'PATCH',
        data: requestBody
    }

    const successCallback = (response) => {
        dispatch({ type: 'patchAllowOrBlockAccessToAdvisorPortalRequestSuccess' });
        const successMessage =
            response.data?.message || `Access is Changed Successfully !`;
        Store.dispatch(handleNotification(successMessage, 'success'));
        handleRequestSuccessCallback && handleRequestSuccessCallback(response?.data)
    }
    const errorCallback = (error) => {
        dispatch({ type: 'patchAllowOrBlockAccessToAdvisorPortalRequestFailed' });
        let message = 'Access Change is Failed !';
        if (error?.response?.status === 500) {
            message = error?.response?.data?.message || 'Please Check Your Network Connection !';
        }
        Store.dispatch(handleNotification(message, 'error'));
        handleRequestFailedCallback && handleRequestFailedCallback(error)
    }

    http_request(requestData, successCallback, errorCallback)
}