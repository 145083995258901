import { ApprovalOutlined, CancelOutlined, CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Grid, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CommonModal from 'common/material/CommonModal';
import InputField from 'common/material/InputField';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetPayments } from 'redux/Actions/paymentsAction';
import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';
import LoadingSpinner from 'ui-component/LoadingSpinner';
import PaymentApprovalRejectConfirmationModal from 'views/paymentHistory/modals/PaymentApprovalRejectConfirmationModal';
import { currencyNumberFormat } from 'views/utilities/StringFormatUtils';

const PaymentHistoryDetails = (props) => {
  const { isOpened, confirmationData, handleDialogOpened } = props;
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //payment store
  const paymentsReducer = useSelector((state) => state?.paymentsReducer);
  const isGetFilteredPaymentsRequestLoading = paymentsReducer?.isGetFilteredPaymentsRequestLoading;
  const filteredPaymentsData = paymentsReducer?.filteredPaymentsData;
  const paymentsGetRequestFilters = paymentsReducer?.paymentsGetRequestFilters;

  //states
  const [paymentHistoryDataPagination, setPaymentHistoryDataPagination] = useState({
    data: [],
    totalElements: 0,
    pageNo: 1,
    pageSize: 12,
  });
  const [approvalDeclineConfirmationData, setApprovalDeclineConfirmationData] = useState({
    isOpened: false,
    confirmationData: null,
  });

  /**
   * Get Payment Date
   * @param {object} gridCellParams
   * @returns
   */
  const getPaymentDate = (gridCellParams) => {
    return format(new Date(gridCellParams?.value), 'yyyy-MM-dd HH:mm');
  };
  /**
   * Get amount paid with currency
   * @param {*} params
   * @returns
   */
  const getAmountPaidWithCurrency = (params) => {
    let rowData = params?.row;
    return `${currencyNumberFormat(rowData?.amount || 0)} ${rowData?.currency}`;
  };

  //payment history data table columns
  const paymentHistoryDataColumns = [
    {
      field: 'paymentRefNumber',
      headerName: 'Payment Reference Number',
      flex: 1,
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      valueGetter: getPaymentDate,
      flex: 1,
    },
    {
      field: 'paidAmountWithCurrency',
      headerName: 'Amount Paid',
      flex: 1,
      valueGetter: getAmountPaidWithCurrency,
    },
    {
      field: 'method',
      headerName: 'Payment Method',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          (params?.value && (
            <Tooltip title={params?.row?.comment} placement="top" arrow>
              <Chip
                id={params?.row?.paymentId + '-statusTag'}
                label={params.value}
                sx={(theme) => ({
                  color: theme.palette.background.paper,
                  backgroundColor:
                    params.value === 'Rejected'
                      ? theme.palette.red?.[400]
                      : params.value === 'Paid'
                      ? theme.palette.blue?.[400]
                      : params.value === 'Approved'
                      ? theme.palette.green?.[400]
                      : theme.palette.grey?.[400],
                  fontWeight: theme.typography.fontWeightMedium,
                })}
              />
            </Tooltip>
          )) || <></>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={Math.random()}
          id="acceptIncomingPayment"
          color="green"
          icon={<SvgIcon viewBox="0 0 25 25" component={CheckCircle} />}
          onClick={(event) => handleActionClick(event, 'ACCEPT_PAYMENT', params)}
          label="Edit Income"
        />,
        <GridActionsCellItem
          key={Math.random()}
          color="red"
          id="rejectIncomingPayment"
          icon={<SvgIcon viewBox="0 0 25 25" component={CancelOutlined} />}
          onClick={(event) => handleActionClick(event, 'REJECT_PAYMENT', params)}
          label="Delete"
        />,
      ],
    },
  ];

  /**
   *
   * @param {*} event
   * @param {*} actionType [ACCEPT_PAYMENT, REJECT_PAYMENT]
   * @param {*} gridParams
   */
  const handleActionClick = (event, actionType, gridParams) => {
    const rowData = gridParams?.row;
    if (actionType === 'ACCEPT_PAYMENT' || actionType === 'REJECT_PAYMENT') {
      handleConfirmationOpenOrClose(true, {
        actionType,
        data: rowData,
      });
    }
  };

  const handleConfirmationOpenOrClose = (isOpened, confirmationData = null) => {
    setApprovalDeclineConfirmationData((prev) => ({
      ...prev,
      isOpened,
      confirmationData: {
        ...prev.confirmationData,
        ...(confirmationData || { data: null }),
      },
    }));
  };
  /**
   * Handle get organization data
   */
  const handleGetPaymentIncomingHistory = () => {
    const requestDetails = {
      status: 'incoming',
      payerId: confirmationData?.advisorData?._id,
    };
    dispatch(requestGetPayments(requestDetails));
  };
  /**
   * Effect
   */
  useEffect(() => {
    if (isOpened) {
      handleGetPaymentIncomingHistory();
    } else {
      setPaymentHistoryDataPagination((prev) => ({
        ...prev,
        data: [],
        totalElements: 0,
        pageNo: 1,
      }));
    }
  }, [isOpened]);

  /**
   * Effect on store changed
   */
  useEffect(() => {
    if (
      paymentsGetRequestFilters?.status === 'incoming' &&
      Array.isArray(filteredPaymentsData?.paymentHistory) &&
      filteredPaymentsData?.paymentHistory?.length
    ) {
      let payerId = filteredPaymentsData?.payerId;
      let payeeId = filteredPaymentsData?.payeeId;
      let createdAt = filteredPaymentsData?.createdAt;
      let automaticPayment = filteredPaymentsData?.automaticPayment;
      setPaymentHistoryDataPagination((prev) => ({
        ...prev,
        data:
          filteredPaymentsData?.paymentHistory?.map &&
          filteredPaymentsData.paymentHistory.map((singlePaymentDetail) => ({
            ...singlePaymentDetail,
            payerId,
            payeeId,
            createdAt,
            automaticPayment,
          })),
        totalElements: filteredPaymentsData?.paymentHistory?.length,
      }));
    } else {
      setPaymentHistoryDataPagination((prev) => ({
        ...prev,
        data: [],
        totalElements: 0,
      }));
    }
  }, [filteredPaymentsData, paymentsGetRequestFilters]);

  return (
    <Grid container spacing={gridSpacing}>
      {!!isGetFilteredPaymentsRequestLoading && (
        <LoadingSpinner isLoadingSpinner={!!isGetFilteredPaymentsRequestLoading} />
      )}
      <PaymentApprovalRejectConfirmationModal
        isOpened={approvalDeclineConfirmationData?.isOpened}
        confirmationData={approvalDeclineConfirmationData?.confirmationData}
        handleDialogOpened={handleConfirmationOpenOrClose}
        handleApproveRejectSuccessCallback={handleGetPaymentIncomingHistory}
      />
      <Grid item xs={12}>
        <DataGrid
          rows={paymentHistoryDataPagination.data}
          columns={paymentHistoryDataColumns}
          getRowId={(row) => row?._id}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          sx={(theme) => ({
            height: '60vh',
            backgroundColor: theme.palette.background.paper,
          })}
          // components={{
          // 	Pagination: GridPagination
          // }}
          // componentsProps={{
          //   pagination: {
          //     backIconButtonProps: {
          //       color: 'primary',
          //       sx: (theme) => ({
          //         backgroundColor: theme.palette.green?.[100]
          //       })
          //     },
          //     nextIconButtonProps: {
          //       color: 'primary',
          //       sx: (theme) => ({
          //         backgroundColor: theme.palette.green?.[100]
          //       })
          //     }
          //   }
          // }}
        />
      </Grid>
    </Grid>
  );
};

const PaymentHistoryDetailsModal = (props) => {
  const { isOpened, confirmationData, handleDialogOpened } = props;

  return (
    <CommonModal
      open={isOpened}
      dialogTitle="Payment History"
      dialogContent={
        <PaymentHistoryDetails
          confirmationData={confirmationData}
          handleDialogOpened={handleDialogOpened}
          isOpened={isOpened}
        />
      }
      handleDialogOpened={handleDialogOpened}
      fullWidth
      maxWidth="lg"
    />
  );
};

export default PaymentHistoryDetailsModal;
