import { Grid, Skeleton, Stack } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';

const ComposeSkeleton = (props) => {
  /**
   * Uses common classes
   */
  const {
    classes: { formSubContainer },
  } = useCommonStyles();
  return (
    <Grid container spacing={gridSpacing} sx={{ width: '100%', padding: '1rem 0 1rem 1rem' }}>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: '100%', height: '3rem', marginBlock: '0 1.5rem' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="text"
          sx={{
            lineHeight: '1.334em',
            height: '0.875rem',
            width: '5ch',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: '100%', height: '3rem', marginBlock: '0 1.5rem' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: '100%', height: '28.13rem', marginBlock: '0.5rem 0.25rem' }}
        />
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <Stack
          direction="row"
          flex={1}
          flexWrap="nowrap"
          justifyContent="flex-end"
          spacing={gridSpacing}
          className={formSubContainer}
        >
          <Skeleton variant="rounded" sx={{ height: '2.375rem', width: '7.88rem' }} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ComposeSkeleton;
