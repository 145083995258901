import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { backButtonSvg } from "assets/svg/svgImg";

const BackButton = (props) => {
  const navigate = useNavigate();

  const styles = {
    color: "#2E2E2E",
    "&:hover": {
      color: "#2E2E2E",
    },
  };

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={backButtonSvg}
      sx={styles}
      onClick={() => navigate(-1)}
    >
      Back
    </Button>
  );
};

export default BackButton;
