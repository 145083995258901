import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Grow,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Lottie from 'lottie-react';
import * as deleteAnimationData from 'assets/animation/delete.json';
import { useCommonStyles } from 'styles/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function ConfirmationModal(props) {
  const {
    isConfirmationModal,
    closeConfirmationAction,
    modalConfirmAction,
    confirmationModalHeader,
    confirmationModalContent,
    img,
    loading,
  } = props;

  const { classes: commonClasses } = useCommonStyles();

  const style = {
    height: 100,
  };

  return (
    <>
      <Dialog
        open={isConfirmationModal}
        onClose={closeConfirmationAction}
        TransitionComponent={Transition}
        aria-labelledby="conformation dialog"
        scroll="body"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          className={commonClasses.confirmationModalHeader}
          id="conformation-dialog-title"
        >
          {confirmationModalHeader}
          <CancelIcon onClick={closeConfirmationAction} className={commonClasses.dialogCloseBtn} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item xs={12} sm={12} md={3} lg={3} alignItems={'center'}>
              <Box style={{ height: 100 }} alignItems={'center'}>
                <Lottie animationData={img ? img : deleteAnimationData} style={style} />
              </Box>
            </Grid>
            <Grid container item xs={12} sm={12} md={9} lg={9} alignItems={'center'}>
              <Typography
                className={commonClasses.conformationContentText}
                id="conformation-dialog-text"
              >
                {confirmationModalContent}
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Stack direction={'row'} spacing={2} className={commonClasses.modalActionBox}>
                <Button
                  className={commonClasses.cancelBtn}
                  onClick={closeConfirmationAction}
                  variant="outlined"
                >
                  {props.noWord ? props.noWord : 'No'}
                </Button>

                <LoadingButton
                  className={commonClasses.saveBtn}
                  id={'delete-btn'}
                  type="submit"
                  endIcon={<CheckIcon />}
                  loading={loading}
                  loadingPosition="end"
                  variant={'contained'}
                  onClick={modalConfirmAction}
                >
                  {props.yesWord ? props.yesWord : 'Yes'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ConfirmationModal;
