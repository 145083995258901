// assets
import DashboardIcon from '@mui/icons-material/Dashboard';

// constant
const icons = { DashboardIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: null,
    type: 'group',
    isDivider: true,
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/main/newDashboard',
            icon: icons.DashboardIcon,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
