import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'; //for session storage
// import storage from 'redux-persist/lib/storage'; //for local storage

// login, signup
import { authenticationReducer } from './Reducers/authenticationReducer';
//modules
import { documentReducer } from './Reducers/DocumentReducer';
import { customization } from './Reducers/LayoutReducer';
import { notificationReducer } from './Reducers/handleNotificationReducer';
import { modalReducer } from './Reducers/modalReducer';
import { compositeDataReducer } from './Reducers/compositeDataReducer';
import { adminDashboardReducer } from './Reducers/adminDashboardReducer';
import { promoCodesDataReducer } from './Reducers/promoCodesReducer';
import { advisorProfileReducer } from './Reducers/advisorProfileReducer';
import { paymentsReducer } from './Reducers/paymentsReducer';
import { taxSettingReducer } from './Reducers/taxSettingReducer';
import { emailPostsReducer } from './Reducers/emailReducer';
import { emailTemplateReducer } from './Reducers/emailTemplateReducer';

const authenticationPersistConfig = {
  key: 'authenticationReducer',
  storage: storageSession,
};

//app reducer
const appReducer = combineReducers({
  authenticationReducer: persistReducer(authenticationPersistConfig, authenticationReducer),
  documentReducer: documentReducer,
  customization: customization,
  notificationReducer: notificationReducer,
  modalReducer: modalReducer,
  compositeDataReducer: compositeDataReducer,
  adminDashboardReducer: adminDashboardReducer,
  promoCodesDataReducer: promoCodesDataReducer,
  advisorProfileReducer: advisorProfileReducer,
  paymentsReducer: paymentsReducer,
  taxSettingReducer: taxSettingReducer,
  emailPostsReducer: emailPostsReducer,
  emailTemplateReducer: emailTemplateReducer,
});
//root reducer
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};
//configured store
const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export default Store;
