import { isDisabled } from '@testing-library/user-event/dist/utils';
import * as R from 'ramda';
import Store from 'redux/Store';

/**
 * Get Option Data from the redux store selection
 * @param {string} compositeKey
 * @returns
 */
export const getOptionsData = (compositeKey) => {
  let { compositeDataReducer: compositeDetails } = Store.getState();
  let optionData = [];
  if (
    Array.isArray(compositeDetails?.compositeData?.[compositeKey]) &&
    !compositeDetails?.compositeRequestLoading
  ) {
    optionData = compositeDetails.compositeData[compositeKey].map((singleOption) => ({
      ...singleOption,
      name:
        (typeof singleOption?.value === 'string' &&
          R.replace(/^./, R.toUpper, singleOption.value)) ||
        singleOption?.value,
    }));
    if (optionData?.length === 0) {
      optionData = [
        {
          id: '',
          value: '',
          name: 'Data Unavailable',
          isDisabled: true,
        },
      ];
    }
  } else if (compositeDetails?.compositeRequestLoading) {
    optionData = [
      {
        id: -1,
        value: -1,
        name: 'Loading..',
        isDisabled: true,
      },
    ];
  }
  return optionData;
};
