import React from 'react';
import { Grid } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import EmilList from 'ui-component/list/EmilList';

import ConfirmationModal from 'common/ConfirmationModal';
import * as deleteAnimationData from 'assets/animation/delete.json';

const SendItems = () => {
  // conformation modal state (Archive client)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = React.useState(false);

  //open conformation modal (Archive client)
  const openConfirmation = (deleteData) => {
    setIsOpenConfirmationModal(true);
  };

  //close conformation modal (Archive client)
  const closeConfirmationAction = () => {
    setIsOpenConfirmationModal(false);
  };

  // handle action conformation modal (Archive client)
  const modalConfirmAction = () => {
    console.log('confirmation action');
  };

  return (
    <Grid container>
      <ConfirmationModal
        isConfirmationModal={isOpenConfirmationModal}
        closeConfirmationAction={closeConfirmationAction}
        modalConfirmAction={modalConfirmAction}
        confirmationModalHeader="Delete the video"
        confirmationModalContent="Would you like to delete this video?"
        img={deleteAnimationData}
      />

      <Grid container item xs={12}>
        <MainCard
          style={{
            backgroundColor: '#F5FFFC',
            border: '1px solid #2EBA88',
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              {[1, 2, 3, 4, 5].map((data, index) => (
                <EmilList deleteVideo={openConfirmation} />
              ))}
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default SendItems;
